import { FC, ReactElement } from 'react';
import { BlockBox } from 'components/common';

import s from './CardLayout.module.scss';

interface CardLayoutProps {
	title: string;
	padding?: string;
	width?: string;
	children: ReactElement;
}

const CardLayout: FC<CardLayoutProps> = ({
	title,
	children,
	padding = '24px',
	width = '690px',
}) => (
	<BlockBox padding={padding} width={width}>
		<h3 className={s.title}>{title}</h3>
		{children}
	</BlockBox>
);

export default CardLayout
