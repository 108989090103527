import React, { FC, useState, useRef } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import { useAppSelector } from 'hooks/redux';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Link } from 'react-router-dom';
import { LangType } from 'store/lang/lang.slice';
import { useOnClickOutside } from 'hooks';
import CustomSelectWithSearch from 'pages/Reports/ReportsComponents/CustomSelectWithSearch/CustomSelectWithSearch';
import { AccessRights } from 'store/user/user.types';
import { ToggleButtonInterface } from '../Header/hooks/useToggleButtons';
import { useStyles } from './ToggleButtons.jss';

interface ToggleButtonsProps {
	alignment: string;
	handleRouteChange: (event: React.MouseEvent<HTMLElement>, newAlignment: string) => void;
	toggleButtons: ToggleButtonInterface[];
	language: LangType | string;
	userPathName: string;
	accessRights: AccessRights | null;
}

const ToggleButtons: FC<ToggleButtonsProps> = ({
	alignment,
	handleRouteChange,
	toggleButtons,
	language,
	userPathName,
	accessRights,
}) => {
	const classes = useStyles();
	const allReports = useAppSelector((state) => state.reports.allReports);
	const [isOpenSelect, setIsOpenSelect] = useState(false);
	const refSelect = useRef<HTMLDivElement>(null);

	const handlerDropdown = (event: any): void => {
		if (isOpenSelect && refSelect.current && !refSelect.current.contains(event.target)) {
			setIsOpenSelect(false);
		}
	};
	useOnClickOutside(refSelect, handlerDropdown);

	const dropdownCloseFunction = () => {
		setIsOpenSelect(false);
	};

	return (
		<ToggleButtonGroup
			value={alignment}
			exclusive
			onChange={handleRouteChange}
			className={classes.toggleButtonGroup}
		>
			{toggleButtons.map((button) => {
				if (button.withSelect)
					return (
						<ToggleButton
							key={button.key}
							value={button.value}
							onClick={() => {}}
							className={classes.headerItemText}
						>
							<div ref={refSelect}>
								<div
									className={classes.toggleButton}
									style={{ position: 'relative' }}
									onClick={() => setIsOpenSelect(!isOpenSelect)}
								>
									{button.title}
								</div>
								<CustomSelectWithSearch
									accessRights={accessRights}
									allReports={allReports}
									language={language}
									isOpenSelect={isOpenSelect}
									dropdownCloseFunction={dropdownCloseFunction}
								/>
							</div>
						</ToggleButton>
					);

				// для страниц в разработке
				if (button.isForProd)
					return (
						<ToggleButton
							key={button.key}
							value={button.value}
							onClick={button.onClick}
							className={classes.headerItemText}
						>
							<Link
								className={classes.toggleButton}
								to={`/${language}/${userPathName}/${button.pathName}`}
							>
								{button.title}
							</Link>
						</ToggleButton>
					);
				return (
					<ToggleButton
						key={button.key}
						value={button.value}
						onClick={button.onClick}
						className={classes.headerItemText}
					>
						<Link className={classes.toggleButton} to={`/${language}/${userPathName}/${button.pathName}`}>
							{button.title}
						</Link>
					</ToggleButton>
				);
			})}
		</ToggleButtonGroup>
	);
};

export default ToggleButtons;
