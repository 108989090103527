/* eslint-disable */
import React, { FC } from 'react';

import { XAxis, YAxis, Line, LineChart, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import { colors } from './ChartsBlock';

type ChartAreaPropsType = {
	data: any[];
	width: number;
	height: number;
	dataKey: string[];
	checkChart: string;
	dataChartLegend: string[];
	dataLegendLabelX: string;
	dataLegendLabelY: string;
};

const useStyles = makeStyles({
	chartListItemWrap: {
		marginTop: '16px',
		display: '-webkit-box',
		'-webkit-line-clamp': 6,
		'-webkit-box-orient': 'vertical',
		overflowY: 'hidden',
		textAlign: 'center',
	},
	chartList: {
		marginBottom: '0',
		textAlign: 'center',
	},
	chartListItem: {
		display: 'inline-flex',
		alignItems: 'center',
		marginRight: '10px',
		fontSize: '11px',
	},
	chartTooltip: {
		maxHeight: '400px',
		height: '100%',
		background: '#fff',
		padding: '16px',
		borderRadius: '10px',
		boxShadow: '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
		width: '300px',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			height: '8px',
			width: '9px',
			backgroundColor: '#fff',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundСolor: '#A3AEBE',
			background: '#A3AEBE',
			borderRadius: '14px',
		},
	},
	legendCircle: {
		borderRadius: '50%',
		width: '6px',
		height: '6px',
		marginRight: '5px',
	},
	tooltip: {
		marginBottom: '8px',
	},
	tooltipTable: {
		height: '100%',
	},
	tableTd: {
		paddingLeft: '10px',
		whiteSpace: 'nowrap',
	},
	lineChart: {
		fontSize: '12px',
	},
	legend: {
		bottom: '0px',
	},
});

const ChartArea: FC<ChartAreaPropsType> = React.memo(
	({ data, width, height, dataKey, checkChart, dataChartLegend, dataLegendLabelX, dataLegendLabelY }) => {
		const classes = useStyles();
		const renderLegend = (props: any) => {
			const { payload } = props;
			return (
				<div className={classes.chartListItemWrap}>
					<ul className={classes.chartList}>
						{payload.map((entry: any, index: any) => (
							<div key={index} className={classes.chartListItem}>
								<span
									className={classes.legendCircle}
									style={{
										background: `${entry.color}`,
									}}
								/>
								<li style={{ color: `${entry.color}`, display: 'inline-flex' }} key={`item-${index}`}>
									{dataChartLegend[index]}
								</li>
							</div>
						))}
					</ul>
				</div>
			);
		};

		const CustomizedTooltip = ({ active, payload, label }: any): JSX.Element | null => {
			if (active && payload && payload.length) {
				return (
					<div className={classes.chartTooltip}>
						<div className={classes.tooltip}>
							<span>{dataLegendLabelX} -&nbsp;</span>
							<span>{label}</span>
						</div>
						<div className={classes.tooltipTable}>
							<table>
								<tbody>
									{payload.map((item: any, index: any) => (
										<tr key={index} style={{ color: `${item.color}` }}>
											<td>{dataChartLegend[index]}:</td>
											<td className={classes.tableTd}>
												{checkChart === 'seconds' || checkChart === 'percent'
													? checkChart === 'seconds'
														? moment.utc(item.value * 1000).format('HH:mm:ss')
														: `${item.value} %`
													: item.value}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				);
			}
			return null;
		};

		return (
			<ResponsiveContainer width="100%" height={height}>
				<LineChart
					width={width}
					height={height}
					data={data}
					margin={{
						top: 10,
						right: 20,
						left: 20,
						bottom: 0,
					}}
					className={classes.lineChart}
				>
					<XAxis
						dataKey="idName"
						label={{
							value: `${dataLegendLabelX}`,
							offset: -5,
							position: 'insideBottomRight',
							fontSize: '13px',
						}}
					/>

					{checkChart === 'seconds' ? (
						<YAxis
							label={{
								value: `${dataLegendLabelY}`,
								angle: -90,
								position: 'insideLeft',
								viewBox: { x: 10, y: 30, width: '100%', height: 30 },
								fontSize: '13px',
							}}
							tickFormatter={(timeStr) => moment.utc(timeStr * 1000).format('HH:mm:ss')}
						/>
					) : (
						<YAxis
							label={{
								value: `${dataLegendLabelY}`,
								angle: -90,
								position: 'insideLeft',
								viewBox: { x: 10, y: 30, width: '100%', height: 30 },
								fontSize: '13px',
							}}
						/>
					)}
					<Tooltip wrapperStyle={{ zIndex: 100, pointerEvents: 'auto' }} content={<CustomizedTooltip />} />
					<Legend className={classes.legend} content={renderLegend} iconType="circle" />
					{dataKey.map((item, index) => (
						<Line
							key={index}
							type="monotone"
							dataKey={item}
							stroke={colors[index % colors.length]}
							strokeWidth={3}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
		);
	},
);

export default ChartArea;
