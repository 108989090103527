import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	toggleButtonGroup: {
		cursor: 'pointer',
	},
	headerItemText: {
		color: '#738094 !important',
		transition: '0.4s !important',
		padding: '0 !important',
		// @ts-ignore
		textTransform: 'none !important',
		'&.Mui-selected, &:active': {
			backgroundColor: '#E3E8EF !important',
			color: '#000 !important',
		},
		border: 'none !important',
		outline: 'none !important',
		backgroundColor: '#ffffff !important',
	},
	toggleButton: {
		opacity: '0.7',
		padding: '10px 20px !important',
		textDecorationColor: '#000 !important',
		color: '#000 !important',
	},
});
