import React, { FC } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';

import { CustomMenuList, CustomOption } from '../MultiValueSelect.components';
import { selectCustomStylesCreator } from '../MultiValueSelect.jss';
import { ICreatableMultiSelect } from '../multiValuesSelect';

/**
 * Селект с выпадающим списком и инпутом
 * Значения можно добавлять через список и инпут
 */
const MultiSelectWithCreate: FC<ICreatableMultiSelect> = ({
	value,
	options,
	isOptionSelected,
	onOptionSelect,
	onCreateOption,
	onInputChange,
	placeholder,
	height,
	backgroundColor,
	border,
	color,
}) => {
	const { language } = useAppSelector((state: RootState) => state.lang);
	const styles: any = selectCustomStylesCreator({
		height,
		backgroundColor,
		border,
		color,
	});

	return (
		<CreatableSelect
			value={value}
			options={options}
			onChange={onOptionSelect}
			onInputChange={onInputChange}
			onCreateOption={onCreateOption}
			styles={styles}
			closeMenuOnSelect={false}
			isMulti
			createOptionPosition="first"
			formatCreateLabel={(str): string => str}
			tabIndex={0}
			isValidNewOption={(): boolean => true}
			hideSelectedOptions={false}
			components={{
				Menu: options.length > 0 ? components.Menu : () => null,
				MenuList: CustomMenuList,
				Option: CustomOption,
				DropdownIndicator: options.length > 0 ? components.DropdownIndicator : null,
			}}
			placeholder={placeholder || translate('all', language)}
		/>
	);
};

export default MultiSelectWithCreate;
