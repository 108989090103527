import React from 'react';
import cn from 'classnames';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BlockBox } from 'components/common';
import { commonTestIds } from 'tests/testid';

const Transition = React.forwardRef(
	(
		props: TransitionProps & {
			children: React.ReactElement<any, any>;
		},
		ref: React.Ref<unknown>,
	) => <Slide direction="up" ref={ref} {...props} />,
);

type AuthModalWindowPropsType = {
	isMWOpen: boolean;
	handleMWClose: () => void;
	text: string;

	width?: string;
	height?: string;
	overflow?: string;
	positionRight?: boolean;
	showCloseBtn?: boolean;
	classNameRoot?: string;
	classNameChildren?: string;
	classNameTitle?: string;
	classNameTitleText?: string;
	boxHeight?: string;
};

export const ModalWindow: React.FC<AuthModalWindowPropsType> = ({
	isMWOpen,
	handleMWClose,

	text,
	children,

	width,
	height,
	overflow = 'inherit',
	positionRight,
	showCloseBtn = true,
	classNameRoot,
	classNameChildren,
	classNameTitle,
	classNameTitleText,
	boxHeight = 'auto',
}) => {
	const useStyles = makeStyles({
		mwWrapper: {
			height: '100% !important',
			'& .MuiPaper-root': {
				overflow: 'inherit',
				borderRadius: '10px !important',
				height: height ? `${height} !important` : 'auto',
				width: width ? `${width} !important` : '400px',
				maxWidth: width ? `${width} !important` : '600px',
				margin: '0',
			},
		},
		mwRoot: {
			padding: '24px !important',
		},
		mwWrapperRight: {
			height: '100% !important',
			'& .MuiDialog-container': {
				height: '100vh !important',
				justifyContent: 'flex-end !important',
			},
			'& .MuiPaper-root': {
				borderRadius: '0px !important',
				height: '100vh !important',
				maxHeight: '100% !important',
				maxWidth: '33% !important',
				width: '33% !important',
				margin: '0',
			},
		},
		mwTitle: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			position: 'relative',
		},
		mwTitleText: {
			fontWeight: '700 !important',
		},
	});
	const classes = useStyles();

	return (
		<Dialog
			data-testid={commonTestIds.modalWindow}
			disableScrollLock
			className={cn({
				[classes.mwWrapperRight]: positionRight,
				[classes.mwWrapper]: !positionRight,
			})}
			open={isMWOpen}
			onClose={handleMWClose}
			style={{ zIndex: '100' }} // если поломается где-то zindex, то убрать
		>
			<BlockBox overflow={overflow} height={boxHeight} className={cn(classes.mwRoot, classNameRoot)}>
				{/* Шапка */}
				<div className={cn(classes.mwTitle, classNameTitle)}>
					<Typography className={cn(classes.mwTitleText, classNameTitleText)}>{text}</Typography>
					{showCloseBtn && (
						<IconButton onClick={handleMWClose} data-testid="closePopupButton">
							<CloseIcon style={{ color: '#000000', width: '15px', height: '15px' }} />
						</IconButton>
					)}
				</div>

				<div className={classNameChildren}>{children}</div>
			</BlockBox>
		</Dialog>
	);
};

export default ModalWindow;
