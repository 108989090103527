/* eslint-disable */
import { FC } from 'react';
import isEqual from 'lodash/isEqual';
import Select, { components } from 'react-select';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';

import { CustomMenuList, CustomOption, MultiValue } from '../MultiValueSelect.components';
import { selectCustomStylesCreator } from '../MultiValueSelect.jss';
import { IMultiSelectFromOptions, OptionType } from '../multiValuesSelect';

/**
 * Селект с выпадающим списком и инпутом
 * Значения можно добавлять только через список
 * С помощью инпута можно искать значения в списке
 */
const MultiSelectFromOptions: FC<IMultiSelectFromOptions> = ({
	value,
	options,
	isOptionSelected,
	onOptionSelect,
	placeholder,
	valueHandler,
	localValue,
	height,
}) => {
	const { language } = useAppSelector((state: RootState) => state.lang);
	const styles: any = selectCustomStylesCreator({ height });

	const onMenuClose = () => {
		const isEqualValue = isEqual(value, localValue);
		if (!isEqualValue) valueHandler(localValue);
	};



	return (
		<Select
			value={value}
			options={options}
			isOptionSelected={isOptionSelected}
			onChange={onOptionSelect}
			styles={styles}
			openMenuOnFocus
			placeholder={placeholder || translate('all', language)}
			components={{
				Menu: options && options.length > 0 ? components.Menu : () => null,
				Option: CustomOption,
				MultiValue,
				MenuList: CustomMenuList,
			}}
			onMenuClose={onMenuClose}
			isClearable
			closeMenuOnSelect={false}
			isMulti
			isSearchable
			hideSelectedOptions={false}
		/>
	);
};

export default MultiSelectFromOptions;
