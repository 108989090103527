import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip } from 'components/common';

type DisableMaskProps = {
	disable: boolean;
	tooltip?: string;
	opacity?: string;
};

const DisableMask: FC<DisableMaskProps> = ({ disable, tooltip, opacity, children }) => {
	const useStyles = makeStyles({
		mask: {
			pointerEvents: 'none',
			cursor: 'not-allowed',
			opacity: opacity || '0.6',
		},
	});
	const classes = useStyles();
	return (
		<Tooltip title={disable && tooltip ? tooltip : ''} followCursor>
			{disable ? (
				<div style={{ cursor: 'not-allowed' }}>
					<div className={classes.mask}>{children}</div>
				</div>
			) : (
				<div>{children}</div>
			)}
		</Tooltip>
	);
};

export default DisableMask;
