import React, { FC, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { MultiValue } from 'react-select/dist/declarations/src/types';
import { CircularProgress } from '@mui/material';
import { getSearchChecklistsSuggestions, getSearchTagsSuggestions } from 'store/calls/actions';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';
import { RootState } from 'store/store';
import { useDebounceCB } from 'hooks/useDebounce';
import { getOptionsForChecklistSearch, getOptionsForComplexSelect } from 'utils/convertDataForSearchFilters/convertData';
import { IMultiSelectWithSearch, OptionType, ISuggest, IChecklistSuggest } from '../multiValuesSelect';
import { getMultiSelectStyles, selectCustomStylesCreator } from '../MultiValueSelect.jss';
import { ComplexChecklistOption, ComplexTagOption, CustomMenuList } from '../MultiValueSelect.components';

const searchConfig: Record<string, any> = {
	search_by_tags: {
		cb: getSearchTagsSuggestions,
		option: ComplexTagOption,
	},
	search_by_checklists: {
		cb: getSearchChecklistsSuggestions,
		option: ComplexChecklistOption,
	},
};

// TODO: разнести на 2 компонента: базовый ui и компонент с логикой для фильтров

/**
 * Селект с поиском. Результаты поиска
 * отображаются в выпадающем списке
 * Предназначен для фильтров звонков (по тегам, по чеклистам)
 * Апи запросы захардкожены
 */
const MultiSelectWithSearchCriteria: FC<IMultiSelectWithSearch> = ({
	value,
	placeholder,
	height,
	valueHandler,
	conditionItemId,
	criteriaKey,
}) => {
	const dispatch = useAppDispatch();
	const { language } = useAppSelector((state: RootState) => state.lang);

	const [suggestions, setSuggestions] = useState<OptionType[]>([]);
	const [selectedValues, setSelectedValues] = useState<OptionType[]>(value);

	const styles: any = selectCustomStylesCreator({ height, menuWidth: '450px' });
	const { selectListWrapper } = getMultiSelectStyles();

	const getSuggestions = async (inputValue: string) => {
		if (!inputValue.length) return [];
		// выбор АПИ в зависимости от конкретного фильтра
		const response = await dispatch(searchConfig[criteriaKey].cb(inputValue));
		// @ts-ignore
		const data: ISuggest[] | IChecklistSuggest[] = response.payload.data;
		let options: OptionType[];

		switch (criteriaKey) {
			case 'search_by_checklists':
				options = getOptionsForChecklistSearch(data as IChecklistSuggest[], inputValue);
				break;
			case 'search_by_tags':
			default:
				options = getOptionsForComplexSelect(data as ISuggest[], inputValue);
				break;
		}
		setSuggestions(options);
		return options;
	};

	const onLoadSuggestions = useDebounceCB((inputValue: string, callback: any) => {
		getSuggestions(inputValue).then(options => callback(options));
	}, 1000);

	const handleOptionSelect = (newValues: MultiValue<OptionType>) => {
		setSelectedValues(newValues as OptionType[]);
	};

	const loadingMessage = () => (
		<div className={selectListWrapper}>
			<CircularProgress color="secondary" />
		</div>
	);
	const noOptionsMessage = () => (
		<div className={selectListWrapper}>
			{translate('noOptionsMessage', language)}
		</div>
	);

	useEffect(() => {
		setSelectedValues(value);
	}, [value]);

	return (
		<AsyncSelect
			value={selectedValues}
			styles={styles}
			isClearable
			isMulti
			closeMenuOnSelect={false}
			hideSelectedOptions={false}
			loadOptions={onLoadSuggestions}
			defaultOptions={suggestions}
			placeholder={placeholder}
			onChange={handleOptionSelect}
			onMenuClose={() => {
				valueHandler(selectedValues, undefined, conditionItemId);
				setSuggestions([]);
			}}
			loadingMessage={loadingMessage}
			noOptionsMessage={noOptionsMessage}
			openMenuOnFocus={false}
			openMenuOnClick={false}
			components={{
				DropdownIndicator: () => null,
				LoadingIndicator: () => null,
				MenuList: CustomMenuList,
				Option: searchConfig[criteriaKey].option,
			}}
		/>
	);
};

export default MultiSelectWithSearchCriteria;
