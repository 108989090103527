import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';
import { translate } from 'localizations';

export const useCriteriaTranslations = () => {
	const { language } = useAppSelector((state: RootState) => state.lang);

	const placeholdersText = {
		client_phone: `${translate('reportClientPhone', language)}`,
		operator_phone: `${translate('reportOperatorPhone', language)}`,
		client_phrases: `${translate('setDictOrText', language)}`,
		operator_phrases: `${translate('setDictOrText', language)}`,
		call_duration: `${translate('setSigns', language)}`,
		operator_talk_time: `${translate('setSigns', language)}`,
		client_talk_time: `${translate('setSigns', language)}`,
		silence_duration: `${translate('setSigns', language)}`,
		operator_talk_time_percentage: `${translate('setTextInNumber', language)}`,
		client_talk_time_percentage: `${translate('setTextInNumber', language)}`,
		silence_time_percentage: `${translate('setTextInNumber', language)}`,
	};

	const titlesText = {
		client_phone: `${translate('callsCustomer', language)}`,
		operator_phone: `${translate('callsEmployee', language)}`,
		client_phrases: `${translate('dictOrText', language)}`,
		operator_phrases: `${translate('dictOrText', language)}`,
	};

	return { placeholdersText, titlesText };
};
