import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import Button from '@mui/material/Button';
import { ArrowPaginationRight } from 'components/Icons/ArrowPaginationRight';
import { ArrowPaginationLeft } from 'components/Icons/ArrowPaginationLeft';
import styles from './styles.module.scss';
import { useStyles } from './UniversalPagination.jss';

interface IPaginationProps {
	currentPage: number;
	pageCount: number | null;
	isLoading: boolean;
	onSelectPage: (pageNumber: number) => void;
	foundItems?: number;
	limitPerPage?: number;
}

const UniversalPagination: React.FC<IPaginationProps> = ({
	currentPage,
	pageCount: _pageCount,
	onSelectPage,
	isLoading,
	foundItems = 0,
	limitPerPage = 100,
}) => {
	const classes = useStyles();
	const pageCount = typeof _pageCount === 'number' ? Math.round(_pageCount / limitPerPage) : undefined;

	const [windowNumber, setWindowNumber] = useState<string>('');

	useEffect(() => {
		setWindowNumber(String(currentPage));
	}, [currentPage]);

	const selectPage = (page: number): void => {
		onSelectPage(page);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const goToPage = (): void => {
		if (windowNumber === '0' || !windowNumber) {
			selectPage(1);
			setWindowNumber('1');
		} else {
			selectPage(Number(windowNumber));
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === 'Enter') {
			goToPage();
		}
	};

	const handleChange = (value: string): void => {
		const isNumeric = /^\d*$/.test(value);
		if (!isNumeric) {
			setWindowNumber(String(currentPage));
			return;
		}

		if (pageCount) {
			const countString = String(pageCount);
			if (parseInt(value, 10) > pageCount) {
				setWindowNumber(countString);
			} else if (value.length > countString.length) {
				setWindowNumber(value.slice(0, countString.length));
			} else {
				setWindowNumber(value);
			}
		}
	};

	return (
		<div className={styles.root}>
			{foundItems > limitPerPage ? (
				<div className={styles.paginationBox}>
					<Pagination
						page={currentPage}
						className={classes.pagination}
						disabled={isLoading}
						onChange={async (event, page) => {
							selectPage(page);
						}}
						count={pageCount}
						color="primary"
						variant="outlined"
						shape="rounded"
						renderItem={(item) => (
							<PaginationItem
								slots={{ previous: ArrowPaginationLeft, next: ArrowPaginationRight }}
								{...item}
							/>
						)}
					/>
					<div className={styles.paginationWindow}>
						<input
							value={windowNumber}
							onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
								handleChange(event.target.value);
							}}
							onKeyDown={handleKeyDown}
							className={
								isLoading
									? `${styles.paginationWindowInputDisabled}`
									: `${styles.paginationWindowInput}`
							}
							type="text"
						/>
						<Button
							onClick={goToPage}
							variant="outlined"
							className={isLoading ? `${styles.buttonDisabled}` : `${styles.button}`}
						>
							<ArrowPaginationRight fill="#fff" />
						</Button>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default UniversalPagination;
