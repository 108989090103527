import { FC } from 'react';
import { FormikProps } from 'formik';
import cn from 'classnames';
import { AccessRightType } from 'store/user/user.types';
import { WithCircularPreloader } from 'components/common/CircularPreloader';
import { translate } from 'localizations';
import { FooterButtons } from 'components/shared';
import { useAppSelector } from 'hooks/redux';
import { ToggleFormType } from 'components/customForms/types';
import ToggleFormItem from './ToggleFormItem';

import s from './styles.module.scss'

interface ToggleFormProps {
	id: string;
	isLoading: boolean;
	options: AccessRightType[];
	form: FormikProps<ToggleFormType> | null;
	className?: string;
}
const ToggleForm: FC<ToggleFormProps> = ({
	id,
	isLoading,
	options,
	form,
	className,
}) => {
	const { language } = useAppSelector(state => state.lang);
	if (!form) return null;

	const noOptions = !options || (options && !options.length);

	return (
		<WithCircularPreloader loading={isLoading} className={s.loader}>
			<form key={id} className={cn(s.form, className)}>
				{noOptions && translate('callsEmpty', language)}
				{Object.keys(form.values).length > 0 && (
					<>
						<div className={s.content}>
							{options.map(item => (
								<ToggleFormItem
									key={item.key}
									isChecked={form.values[item.key]}
									label={item.title}
									onChecked={() => form.setFieldValue(item.key, !form.values[item.key])}
								/>
							))}
						</div>
						<FooterButtons
							footerClassName={s.footer}
							onCancel={form.resetForm}
							onAccept={form.handleSubmit}
							cancelText={translate('cancelButton', language)}
							acceptText={translate('saveButton', language)}
							cancelIsDisabled={!form.dirty}
							acceptIsDisabled={!form.dirty}
						/>
					</>
				)}
			</form>
		</WithCircularPreloader>
	);
};

export default ToggleForm;
