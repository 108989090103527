import React, { FC } from 'react';
import { ContainedSelect, DisableMask } from 'components/common';
import { getConvertedUserValue, optionsUserChangerCreator } from 'utils/user';
import { useAppSelector } from 'hooks/redux';
import { useUserChange } from 'hooks';

import s from './UserSelect.module.scss';

const UserSelect: FC = () => {
	const { user, onlyUsers, childUser } = useAppSelector((state) => state.user);

	const convertedOptions = optionsUserChangerCreator(onlyUsers);
	const convertedValue = getConvertedUserValue(convertedOptions, childUser, user);

	const { handleUserChange } = useUserChange();

	const customStyles = {
		dropdownIndicator: (base: any) => ({
			...base,
			color: '#9254DE',
		}),
	};

	return (
		<div className={s.root} data-testid="userSelect">
			<DisableMask disable={convertedOptions.length < 1}>
				<ContainedSelect
					width="200px"
					height="26px"
					onSelectChange={handleUserChange}
					options={convertedOptions}
					value={convertedValue}
					isSearchable
					customStyleProps={customStyles}
					controlStyles={{
						position: 'relative !important',
						alignItems: 'start !important',
						borderRadius: '30px !important',
						border: '1px #9254DE solid !important',
						fontSize: '12px !important',
						lineHeight: '22px !important',
						color: '#2F3747 !important',
						height: '26px !important',
					}}
					valueContainerStyles={{
						position: 'absolute !important',
						top: '-5px !important',
						width: '100% !important',
					}}
					indicatorStyles={{
						position: 'absolute !important',
						top: '-4px !important',
						right: '0 !important',
					}}
				/>
			</DisableMask>
		</div>
	);
};

export default UserSelect;
