import React, { FC, MouseEventHandler, ReactElement } from 'react';
import cn from 'classnames';
import { Typography } from '@mui/material';
import { getGlobalStyles } from 'styles';

import s from './simpleButton.module.scss';

interface ISimpleButtonProps {
	text?: string;
	onClick: MouseEventHandler<HTMLButtonElement>;
	icon?: ReactElement,
	rootClassName?: string,
	textClassName?: string,
	isDisabled?: boolean
	type?: "button" | "reset" | "submit";
}
const SimpleButton: FC<ISimpleButtonProps> = ({
	text,
	onClick,
	icon,
	rootClassName,
	textClassName,
	isDisabled = false,
	type,
}) => {
	const { resetButton } = getGlobalStyles();
	return (
		<button
			type={type || 'button'}
			className={cn(resetButton, s.root, rootClassName)}
			onClick={onClick}
			disabled={isDisabled}
		>
			<span className={s.icon}>{icon}</span>
			{text && <Typography className={cn(s.text, textClassName)}>{text}</Typography>}
		</button>
	)
};

export  default SimpleButton;
