import React, { FC } from 'react';
import { Alert, CircularProgress, Typography, IconButton } from '@mui/material';
import { InfoSvg, WarningSvg, ErrorSvg, SuccessSvg } from './Alert.svg';

const CrossSVG = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M11.1618 4.83785C11.3896 5.06567 11.3896 5.43503 11.1618 5.66285L5.66187 11.1628C5.43406 11.3906 5.06469 11.3906 4.83688 11.1628C4.60906 10.935 4.60906 10.5656 4.83688 10.3378L10.3368 4.83785C10.5646 4.61004 10.934 4.61004 11.1618 4.83785Z' />
		<path
			d='M4.83688 4.83785C5.06469 4.61004 5.43406 4.61004 5.66187 4.83785L11.1618 10.3378C11.3896 10.5656 11.3896 10.935 11.1618 11.1628C10.934 11.3906 10.5646 11.3906 10.3368 11.1628L4.83688 5.66285C4.60906 5.43503 4.60906 5.06567 4.83688 4.83785Z' />
	</svg>
);

type AlertType = {
	iconType?: 'info' | 'warning' | 'error' | 'loading' | 'success',
	text: string,

	padding?: string,
	boxShadow?: string,
	width?: string,
	canDelete?: boolean
}

const CustomAlert: FC<AlertType> = (
	{
		iconType,

		text,
		padding,
		boxShadow,
		width,
		canDelete,
	},
) => {
	const getIcon = () => {
		if (iconType === 'info') {
			return {
				icon: <InfoSvg fill="#fff" />,
				color: '#0388D1',
			};
		} if (iconType === 'warning') {
			return {
				icon: <WarningSvg fill="#fff" />,
				color: '#F57C00',
			};
		} if (iconType === 'error') {
			return {
				icon: <ErrorSvg fill="#fff" />,
				color: '#D32F2F',
			};
		} if (iconType === 'loading') {
			return {
				icon: <CircularProgress size={30} style={{ width: '16px', height: '16px' }} />,
				color: '#fff',
			};
		} if (iconType === 'success') {
			return {
				icon: <SuccessSvg fill="#fff" />,
				color: '#389E0D',
			};
		} 
		return {
			icon: <div />,
			color: '#EBEFF4',
		};
		
	};
	const alertObject = getIcon();
	const isWhite = iconType !== 'loading' && iconType !== undefined;
	return (
		<Alert
			icon={alertObject.icon}
			sx={{
				padding: padding || '0 10px',
				width: width || 'auto',
				fontSize: '15px',
				backgroundColor: alertObject.color,
				// boxShadow: boxShadow ? boxShadow : "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
				'& .MuiAlert-icon': {
					paddingTop: '10px !important',
				},
			}}
		>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography style={{ color: isWhite ? '#fff' : '#000' }}>{text}</Typography>
				{canDelete && (
					<IconButton size="small" onClick={() => {
					}} style={{ marginLeft: '15px' }}>
						<CrossSVG fill={isWhite ? '#fff' : '#000'} />
					</IconButton>
				)}
			</div>
		</Alert>
	);
};

export default CustomAlert;
