import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	wrapperRangePicker: (props: { showTime: boolean | undefined }) => ({
		width: props.showTime ? '340px' : '238px',
		height: '100%',
		'& .ant-space-item': {
			height: '100%',
		},
	}),
	rangePicker: {
		height: '100%',
		borderRadius: '5px',
		'& .ant-space-item': {
			height: '100%',
		},
		'& .ant-picker-range': {
			height: '100%',
		},
	},
	rangePickerError: {
		height: '100%',
		borderColor: '#FF7875 !important',
		boxShadow: '0 0 0 2px rgb(255, 120, 117, 20%)',
		'& .ant-space-item': {
			height: '100%',
		},
		'& .ant-picker-range': {
			height: '100%',
		},
	},

	dropdown: {
		'& .ant-picker-panel-container': {
			display: 'flex !important',
			flexDirection: 'row !important',
			minWidth: 'auto !important',
		},
		'& .ant-picker-footer': {
			borderLeft: '1px solid #f0f0f0',
			minWidth: 'auto !important',
		},
		'& .ant-picker-ranges': {
			display: 'flex !important',
			flexDirection: 'column !important',
			padding: '11px 24px 11px 12px !important',
		},
		'& .ant-picker-ranges > li': {
			display: 'flex !important',
			padding: '9px 0 !important',
		},
		'& .ant-picker-ranges .ant-picker-preset > .ant-tag-blue': {
			color: '#2F3747 !important',
			background: '#fff !important',
			fontSize: '14px !important',
			borderColor: '#fff !important',
		},
		'& .ant-picker-ranges .ant-picker-preset:hover > .ant-tag-blue': {
			color: '#722ED1 !important',
		},
		'& .ant-tag-blue: hover': {
			color: '#722ED1 !important',
		},
	},
});
