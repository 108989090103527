import { makeStyles } from '@mui/styles';
import variables from 'styles/variables.scss';

export const getStyles = makeStyles({
	criteriaSingle: {
		width: '100%',
	},
	criteriaWithConditions: {
		width: 'calc(100% - 16px)',
		padding: '16px 18px 16px 16px',
		backgroundColor: variables.gray_3,
		borderRadius: '5px',
		marginBottom: '21px',
	},
	criteriaMarkup: {
		width: 'auto',
		margin: 0,
	},
	criteriaItem: {
		padding: '0 !important',
	},
	criteriaItemWrapper: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 0 8px 0',
	},
	deleteButton: {
		marginLeft: '10px',
	},
	addButton: {
		marginBottom: '21px',
	},
	addButtonTop: {
		marginTop: '5px',
	},
	criteriaReportsBlock: {
		width: 'calc(66.666% - 16px)',
	},
});
