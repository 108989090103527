import React, { FC } from 'react';
import { CriteriaBlockPage, CriteriasType } from 'store/search/search.types';
import CriteriaItem from '../CriteriaItem';
import CriteriaWithConditions from '../CriteriaWithConditions';
import { HandlerOnChange, OnAddCondition, Page } from '../CriteriasList';

interface ICriteriaListSimple {
	allCriterias: CriteriasType[];
	otherCriteria: CriteriasType[];
	complexCriteria: CriteriasType[];
	block?: CriteriaBlockPage;
	handlerOnChange?: HandlerOnChange;
	isHandlerFunction?: boolean;
	page: Page;
	onAddCondition?: OnAddCondition;
	onDeleteCondition?: OnAddCondition;
}
const CriteriaSimpleList: FC<ICriteriaListSimple> = ({
	allCriterias,
	otherCriteria,
	complexCriteria,
	block,
	handlerOnChange,
	isHandlerFunction,
	page,
	onAddCondition,
	onDeleteCondition,
}) => (
	<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
		{otherCriteria.map((criteria) => { // @ts-ignore
			const compResult = allCriterias.filter(v => v.key === criteria.key);
			return (
				<div style={{ display: 'inline-flex', width: '33.333%' }}>
					<CriteriaItem
						title={criteria.title}
						criteriaFull={compResult[0]}
						criteriaCurrent={criteria}
						block={block as CriteriaBlockPage}
						handlerOnChange={handlerOnChange}
						isHandlerFunction={isHandlerFunction}
						page={page}
					/>
				</div>
			);
		})}
		{complexCriteria.length > 0 && (
			<CriteriaWithConditions
				criteriaKey=''
				allCriterias={allCriterias as CriteriasType[]}
				data={complexCriteria}
				block={block as CriteriaBlockPage}
				index={0} // зачем нужен index?
				handlerOnChange={handlerOnChange}
				isHandlerFunction={isHandlerFunction}
				page={page}
				onAddCondition={onAddCondition}
				onDeleteCondition={onDeleteCondition}
			/>
		)}
	</div>
);

export default CriteriaSimpleList;
