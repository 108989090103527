/* eslint-disable */
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Alert from '../Alert/Alert';
import { useAppSelector } from '../../../hooks/redux';

export type SnackbarParamsType = 'info' | 'warning' | 'error' | 'success';

export type SnackbarParams = {
	type: SnackbarParamsType;
	text: string;
	time?: number;
};

// TODO: Refactoring
// Поменять название компонента на SnackBar
// createSnackbarOptions вынести в хук
export function createSnackbarOptions(snackbarParams: SnackbarParams | null) {
	if (snackbarParams) {
		const snackbarOptions:
			| {
					content: () => JSX.IntrinsicElements;
					persist?: boolean;
					autoHideDuration?: number;
			  }
			| {} = {
			content: () => (
				<div style={{ display: 'flex' }}>
					<Alert canDelete iconType={snackbarParams.type} text={snackbarParams.text} />
				</div>
			),
		}; // @ts-ignore
		if (snackbarParams.time) snackbarOptions.autoHideDuration = snackbarParams.time; // @ts-ignore
		return snackbarOptions;
	}
}

const useNotifier = () => {
	const snackbarParams = useAppSelector((state) => state.lang.snackbar);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		enqueueSnackbar(null, createSnackbarOptions(snackbarParams));
	}, [snackbarParams]);
};

export default useNotifier;
