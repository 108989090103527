import React, { FC } from 'react';
import cn from 'classnames';
import { Typography } from '@mui/material';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { CriteriaBlockPage, CriteriasType } from 'store/search/search.types';
import { CriteriaWithConditions, Hint } from 'components/common';
import CriteriaItem from '../CriteriaItem';
import { HandlerOnChange, OnAddCondition, Page, SortedCriteriasListType } from '../CriteriasList';
import { useCriteriaStyles } from '../hooks/useCriteriaStyles';

interface ICriteriaMultiList {
	activeCriterias: CriteriasType[];
	allCriterias: CriteriasType[];
	sortedCriterias: SortedCriteriasListType[];
	block?: CriteriaBlockPage;
	handlerOnChange?: HandlerOnChange;
	isHandlerFunction?: boolean;
	page: Page;
	onAddCondition?: OnAddCondition;
	onDeleteCondition?: OnAddCondition;
	simpleListWithTitles?: boolean;
}

const CriteriaMultiList: FC<ICriteriaMultiList> = ({
	activeCriterias,
	allCriterias,
	sortedCriterias,
	block,
	handlerOnChange,
	isHandlerFunction,
	page,
	onAddCondition,
	onDeleteCondition,
	simpleListWithTitles,
}) => {
	const { language } = useAppSelector(state => state.lang);
	const { classes, getBlockClassName, getBlockSearchClassName } = useCriteriaStyles();

	return (
		<div className={simpleListWithTitles ? classes.searchItemsFullWidth : classes.searchItems}>
			{activeCriterias.length > 0
				? allCriterias && sortedCriterias.map((criteriasObj, index) => (
					<div
						className={cn(getBlockClassName(criteriasObj.blockNumber, simpleListWithTitles || false))}
						key={criteriasObj.blockNumber}
					>
						{/* eslint-disable-next-line eqeqeq */}
						{simpleListWithTitles && criteriasObj.blockNumber === 3 && (
							<div className={classes.searchTitle}>
								<div className={classes.searchTitleLeft}>
									<Typography className={classes.searchTitleTitle} variant="subtitle1">
										{translate('callsParameters', language)}
									</Typography>
									<Hint
										title={translate('tooltipParam', language)}
										label={translate('whatIsIt', language)}
									/>
								</div>
							</div>
						)}
						{criteriasObj.blockNumber === 4 && (
							<div className={classes.searchTitle}>
								<div className={classes.searchTitleLeft}>
									<Typography className={classes.searchTitleTitle} variant="subtitle1">
										{translate('reportAddParameters', language)}
									</Typography>
									<Hint
										title={translate('tooltipTags', language)}
										label={translate('whatIsIt', language)}
									/>
								</div>
							</div>
						)}
						<div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
							{criteriasObj.criterias.map((criteria) => {
								const compResult = allCriterias.filter(v => v.key === criteria.key);
								return (
									<div
										className={cn(getBlockSearchClassName(criteria.block))}
										key={criteria.key}
									>
										<CriteriaItem
											title={criteria.title}
											criteriaFull={compResult[0]}
											criteriaCurrent={criteria}
											block={block as CriteriaBlockPage}
											handlerOnChange={handlerOnChange as HandlerOnChange}
											isHandlerFunction={isHandlerFunction}
											page={page}
										/>
									</div>
								);
							})}
							{Object.keys(criteriasObj.complexCriterias).length > 0 && Object.keys(criteriasObj.complexCriterias).map(item => (
								(
									<CriteriaWithConditions
										criteriaKey={criteriasObj.complexCriterias[item][0].key}
										allCriterias={allCriterias}
										data={criteriasObj.complexCriterias[item]}
										block={block as CriteriaBlockPage}
										index={index}
										handlerOnChange={handlerOnChange as HandlerOnChange}
										isHandlerFunction={isHandlerFunction}
										page={page}
										onAddCondition={onAddCondition}
										onDeleteCondition={onDeleteCondition}
									/>
								)
							))}
						</div>
					</div>
				)) : (
					<div>
						<Typography style={{ fontSize: '13px', marginTop: '15px' }}>
							{translate('searchEmpty', language)}
						</Typography>
					</div>
				)}
		</div>
	);
};

export default CriteriaMultiList;
