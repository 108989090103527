import { useEffect, useState } from 'react';
import { CriteriasType } from 'store/search/search.types';
import { useCriteriaTranslations } from './useCriteriaTranslations';

export const useTitleAndPlaceholder = (
	criteriaFull: CriteriasType,
) => {
	const { placeholdersText, titlesText } = useCriteriaTranslations();

	// костыль для плейсхолдеров
	const [customPlaceholder, setCustomPlaceholder] = useState<string>('');
	// костыль для title
	const [customTitle, setCustomTitle] = useState<string>('');

	useEffect(() => {
		Object.keys(placeholdersText).forEach((placeholder: string) => {
			if (placeholder === criteriaFull.key) { // @ts-ignore
				setCustomPlaceholder(placeholdersText[placeholder]);
			}
		});

		Object.keys(titlesText).forEach((title: string) => {
			if (title === criteriaFull.key) { // @ts-ignore
				setCustomTitle(titlesText[title]);
			}
		});
	}, [criteriaFull]);

	return { customTitle, customPlaceholder };
};
