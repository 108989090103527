import React, { FC, ReactElement } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { BlockBox } from 'components/common';
import { RedTrash } from 'components/Icons/RedTrashIcon';
import { VerticalMenuItem } from './VerticalMenuItem';

import s from './styles.module.scss'

export interface IMenuItem {
	to: string;
	text: string;
	isShown: boolean;
	inDev?: boolean;
	parentRoute?: string;
	indicator?: ReactElement | null;
}

export interface VerticalMenuProps {
	menuItems: IMenuItem[];
	hasActions: boolean;
	userName?: string;
	userLogin?: string;
	handleAction: () => void;
}

const VerticalMenu: FC<VerticalMenuProps> = ({
	menuItems,
	userName,
	userLogin,
	hasActions,
	handleAction,
}) => (
	<div className={s.menu}>
		<BlockBox>
			{userName ? (
				<div className={s.header}>
					<Typography className={s.headerName}>
						{userName}
					</Typography>
					<Typography className={s.headerLogin}>
						{userLogin}
					</Typography>
					{hasActions && (
						<button onClick={handleAction} className={s.actions}>
							<RedTrash />
						</button>
					)}
				</div>
			) : (
				<div className={s.header}>
					<Skeleton variant="text" width={150} height={20} />
					<Skeleton variant="text" width={150} height={20} />
				</div>
			)}
			<div className={s.list}>
				{menuItems.map(({ to, text, isShown, inDev, parentRoute, indicator }) => {
					if (isShown) {
						return (
							<VerticalMenuItem
								key={text}
								to={to}
								text={text}
								inDev={inDev}
								parentRoute={parentRoute}
								indicator={indicator}
							/>
						)
					}
					return null;
				})}
			</div>
		</BlockBox>
	</div>
)

export default VerticalMenu;
