import React, { FC } from 'react';

import s from './TextWithIndicator.module.scss';

interface TextWithIndicatorProps {
	showIndicator: boolean;
	color: string;
	text: string;
}

const TextWithIndicator: FC<TextWithIndicatorProps> = ({
	showIndicator,
	color,
	text,
}) => (
	<div className={s.root}>
		{showIndicator && (
			<div
				className={s.indicator}
				style={{
					backgroundColor: color,
				}}
			/>
		)}
		<span className={s.label}>{text}</span>
	</div>
);

export default TextWithIndicator;
