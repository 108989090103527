import { FC } from 'react';
import styles from './style.module.scss';

interface MainLoadingProps {
	logo: string;
	logoTitle: string;
	loading: string;
	loadingTitle: string;
}

const MainLoading: FC<MainLoadingProps> = ({ logo, logoTitle, loading, loadingTitle }) => (
	<div className={styles.wrapper}>
		<div className={styles.wrapperImg}>
			<img src={logo} alt={logoTitle} />
		</div>
		<div>
			<img src={loading} alt={loadingTitle} />
		</div>
	</div>
);

export default MainLoading;
