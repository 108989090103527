import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

type ContainedSelectStylesType = {
	width?: string;
	justify?: string;
	marginRight?: string;
};

export const useMuiContainedSelectStyles = makeStyles<Theme, ContainedSelectStylesType>((theme: Theme) => ({
	selectBox: {
		width: (props) => (props.width ? props.width : 'auto'),
		display: 'flex !important',
		justifyContent: (props) => (props.justify ? props.justify : 'left'),
		alignItems: 'center',
		marginRight: (props) => (props.marginRight ? props.marginRight : '0'),
	},
	selectMenuListInput: {
		width: '228px',
		margin: '16px 24px',
		outline: 'none',
		backgroundColor: '#F8FAFC',
		border: '1px solid #E3E8EF',
		borderRadius: '5px',
		padding: '10px 30px 10px 12px',
		color: '#738094',
		fontSize: '14px',
		position: 'relative',
	},
	selectMenuListInputIcon: {
		position: 'absolute',
		left: '262px',
		top: '23px',
		color: '#738094',
		width: '11px',
	},
	selectOption: {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '5px 24px',
	},
	selectArrow: {
		marginRight: '10px',
	},
	selectArrowOnArrow: {
		marginBottom: '5px',
	},
	selectCheckBox: {
		'&.MuiCheckbox-root': {
			backgroundColor: '#E3E8EF',
		},
	},
	selectSelectBox: {
		position: 'relative',
	},
	selectPlaceholder: {
		// @ts-ignore
		position: 'absolute',
		left: '11px !important',
		top: '9px !important',
	},
}));

type containedSelectStylesParamsType = {
	width?: string;
	height?: string;
	padding?: string;
	controlStyles?: any;
	valueContainerStyles?: any;
	indicatorStyles?: any;
};

export function createTextSelectStyles({
	width,
	height,
	padding,
	controlStyles,
	valueContainerStyles,
	indicatorStyles,
}: containedSelectStylesParamsType) {
	return {
		container: (provided: any) => ({
			...provided,
			width,
			height: height || '36px',
		}),
		menu: (provided: any) => ({
			...provided,
			overflow: 'hidden',
			overflowY: 'auto',
			zIndex: '1000',
		}),
		option: () => ({
			cursor: 'pointer',
			padding: '10px 24px 10px 10px',
			fontFamily: 'Inter, sans-serif',
			fontSize: '14px',
			backgroundColor: '#ffffff',
			color: '#000',
			'&:hover': {
				backgroundColor: '#F8FAFC',
				color: '#722ED1',
			},
		}),
		control: (provided: any) => ({
			...provided,
			cursor: 'pointer',
			width: '100%',
			minHeight: height || '36px',
			height: height || '36px',
			padding: padding || '0',
			border: '1px solid #E3E8EF',
			boxShadow: 'none',
			'&:hover': {
				borderColor: '#E3E8EF',
			},
			minWidth: '70px',
			borderRadius: '5px',
			backgroundColor: '#F8FAFC',
			...controlStyles,
		}),
		placeholder: (provided: any) => ({
			...provided,
			fontSize: '14px',
			color: '#738094',
			fontFamily: 'Inter, sans-serif',
		}),
		valueContainer: (provided: any) => ({
			...provided,
			...valueContainerStyles,
		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			padding: '0',
			color: '#A3AEBE',
			'& svg': {
				height: '16px',
				width: '18px',
			},
			...indicatorStyles,
		}),
		menuList: (provided: any) => ({
			...provided,
			overflowX: 'hidden',
			'&::-webkit-scrollbar': {
				width: '4px',
				backgroundColor: '#f1f1f1',
				outline: 'none',
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#A3AEBE',
				height: '50px',
				borderRadius: '10px',
			},
			'&::-webkit-scrollbar-thumb:hover': {
				background: '#9298A1',
			},
		}),
	};
}
