import { getCriteriaListStyles } from '../styles/CriteriaList.jss';

export const useCriteriaStyles = () => {
	const classes = getCriteriaListStyles();

	const switchSearchClassName = (className: string, isFullWidth: boolean) => {
		const classSwitcher: { [id: string]: any } = {};
		if (isFullWidth) {
			classSwitcher.searchBlock_1 = classes.searchBlockFullWidth_1;
			classSwitcher.searchBlock_2 = classes.searchBlockFullWidth_2;
			classSwitcher.searchBlock_3 = classes.searchBlockFullWidth_3;
			classSwitcher.searchBlock_4 = classes.searchBlockFullWidth_4;
		} else {
			classSwitcher.searchBlock_1 = classes.searchBlock_1;
			classSwitcher.searchBlock_2 = classes.searchBlock_2;
			classSwitcher.searchBlock_3 = classes.searchBlock_3;
			classSwitcher.searchBlock_4 = classes.searchBlock_4;
		}
		return classSwitcher[className];
	}

	const switchSearchItemClassName = (className: string) => {
		const classSwitcher: { [id: string]: any } = {};
		classSwitcher.searchItemBlock_1 = classes.searchItemBlock_1;
		classSwitcher.searchItemBlock_2 = classes.searchItemBlock_2;
		classSwitcher.searchItemBlock_3 = classes.searchItemBlock_3;
		classSwitcher.searchItemBlock_4 = classes.searchItemBlock_4;
		return classSwitcher[className];
	};

	const getBlockClassName = (blockNode: string | number, isFullWidth: boolean) => switchSearchClassName(`searchBlock_${blockNode}`, isFullWidth);
	const getBlockSearchClassName = (blockNode: string | number) => switchSearchItemClassName(`searchItemBlock_${blockNode}`);

	return {
		classes,
		getBlockClassName,
		getBlockSearchClassName,
	};
};
