import { makeStyles } from '@mui/styles';

export const getSelectStyles = makeStyles({
	selectWithCondition: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	block: {
		display: 'flex',
	},
});
