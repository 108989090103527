import React, { FC } from 'react';
import cn from 'classnames';
import { TextField } from '@mui/material';

import { useMuiInputStyles } from './Input.jss';

type InputPropsType = {
	name: string;
	type: string;
	bcColor: string;
	label: string;

	autoComplete?: 'on' | 'off';
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value?: string | null;
	border?: string;
	height?: string;
	testId?: string;
	className?: string;
};

const Input: FC<InputPropsType> = ({
	bcColor,
	height,
	border,
	name,
	type,
	handleChange,
	autoComplete,
	value,
	label,
	className,
	testId,
}) => {
	const classes = useMuiInputStyles({
		bcColor,
		height,
		border,
	});

	return (
		<TextField
			data-testid={testId}
			className={cn(classes.inputRoot, className)}
			name={name}
			type={type}
			onChange={handleChange}
			autoComplete={autoComplete}
			value={value}
			label={label}
		/>
	);
};

export default Input;
