import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	criteriaTemplateTitle: {
		paddingLeft: '16px',
	},
	criteriaTemplateText: {
		fontFamily: '"Inter", sans-serif',
		display: 'flex',
		alignItems: 'center',
		color: '#9254DE',
		marginRight: '5px',
	},
	criteriaTemplateTitleT: {
		color: '#9254DE !important',
		marginRight: '4px !important',
		'@media (max-width: 1440px)': {
			whiteSpace: 'nowrap',
			maxWidth: '240px !important',
			textOverflow: 'ellipsis',
			overflow: 'hidden !important',
		},
		'@media (min-width: 1441px)': {
			whiteSpace: 'nowrap',
			maxWidth: '400px !important',
			textOverflow: 'ellipsis',
			overflow: 'hidden !important',
		},
	},
	criteriaTemplateLength: {
		lineHeight: 1.5,
	},
	criteriaTemplateTextNumber: {
		fontSize: '14px !important',
		fontWeight: '400 !important',
		marginRight: '4px !important',
		color: '#9254DE !important',
	},
});
