import React, { FC, ReactElement } from 'react';

import s from './styles.module.scss';

interface LayoutWithPlaceholderProps {
	showContent: boolean;
	placeholder: string;
	children: ReactElement;
	className?: string;
}

const LayoutWithPlaceholder: FC<LayoutWithPlaceholderProps> = ({ showContent, placeholder, children, className }) => {
	if (showContent) return children;

	return <div className={className || s.placeholder}>{placeholder}</div>;
};

export default LayoutWithPlaceholder;
