import { makeStyles } from '@mui/styles';

export const getCriteriaListStyles = makeStyles(({
	searchWrapper: {
		display: 'flex',
		alignItems: 'start',
		paddingTop: '9.5px',
		'&:last-child': {
			borderBottom: 'none',
		},
	},
	searchItems: {
		width: '100%',
		flexWrap: 'wrap',
		display: 'grid',
		gridTemplateAreas: ` 'block1 block3'
                         'block2 block3' 
                         'block4 block4' `,
		gridTemplateRows: 'auto',
		gridTemplateColumns: '60% 40%',
	},
	searchBlock_1: {
		width: 'calc(100% + 16px)',
		marginRight: '-16px',
		gridArea: 'block1',
	},
	searchBlock_2: {
		width: 'calc(100% + 16px)',
		marginRight: '-16px',
		gridArea: 'block2',
	},
	searchBlock_3: {
		width: 'calc(100% - 40px + 16px)',
		gridArea: 'block3',
		marginLeft: '40px',
		marginRight: '-16px',
	},
	searchBlock_4: {
		width: 'calc(60% + 16px)',
		marginRight: '-16px',
		gridArea: 'block4',
	},
	searchItemsFullWidth: {
		width: '100%',
		flexWrap: 'wrap',
		display: 'flex',
		flexDirection: 'column',
	},
	searchBlockFullWidth_1: {
		width: '100%',
		gridArea: 'block1',
	},
	searchBlockFullWidth_2: {
		width: '100%',
		gridArea: 'block2',
	},
	searchBlockFullWidth_3: {
		width: '100%',
		gridArea: 'block3',
	},
	searchBlockFullWidth_4: {
		width: '100%',
		gridArea: 'block4',
	},
	searchItemBlock_1: {
		width: '50%',
		gridArea: 'block1',
	},
	searchItemBlock_2: {
		width: '50%',
		gridArea: 'block2',
	},
	searchItemBlock_3: {
		width: '100%',
		gridArea: 'block3',
	},
	searchItemBlock_4: {
		width: '100%',
		gridArea: 'block4',
	},
	searchTitle: {
		marginBottom: '8px',
		marginTop: '16px',
		height: '40px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	searchTitleLeft: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	searchTitleTitle: {
		marginRight: '16px !important',
		color: '#2F3747 !important',
		fontWeight: '500 !important',
	},
	questionWrapper: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	questionTitle: {
		color: '#A3AEBE',
		marginLeft: '8px',
	},
}));
