import React, { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import { SortIcon } from 'components/Icons/SortIcon';
import { SortDownIcon } from 'components/Icons/SortDownIcon';
import { SortUpIcon } from 'components/Icons/SortUpIcon';
import styles from './style.module.scss';

interface TableProps {
	dataTable: any;
	columnsTable: any;
	isCellSmall?: boolean;
	sortColumn?: string;
}

const Table: React.FC<TableProps> = ({ dataTable, columnsTable, isCellSmall, sortColumn }) => {
	const data = useMemo(() => dataTable, [dataTable]);
	const columns = useMemo(() => columnsTable, [columnsTable]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

	return (
		<table {...getTableProps()} className={styles.table}>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()} className={styles.headerRow}>
						{headerGroup.headers.map((column: any) => (
							<>
								{column.id === sortColumn ? (
									<th
										{...column.getHeaderProps(column.getSortByToggleProps())}
										className={styles.headCellSort}
									>
										<span>{column.render('Header')}</span>
										<span className={styles.headCellSortIcon}>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<SortDownIcon />
												) : (
													<SortUpIcon />
												)
											) : (
												<SortIcon />
											)}
										</span>
									</th>
								) : (
									<th {...column.getHeaderProps()} className={styles.headCell}>
										{column.render('Header')}
									</th>
								)}
							</>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row) => {
					prepareRow(row);
					return (
						<tr {...row.getRowProps()} className={styles.bodyRow}>
							{row.cells.map((cell) => (
								<td
									{...cell.getCellProps()}
									className={isCellSmall ? styles.bodyCellSmall : styles.bodyCell}
								>
									{cell.render('Cell')}
								</td>
							))}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default Table;
