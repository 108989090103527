import { useDispatch } from 'react-redux';
import { translate } from 'localizations';
import { searchSlice } from 'store';
import { LangType } from 'store/lang/lang.slice';
import { EnvsManager } from 'utils/enviroments';

export interface ToggleButtonInterface {
	key: string;
	value: string;
	onClick: () => void;
	pathName: string;
	title: string;
	withSelect?: boolean;
	isForProd?: boolean;
}
const useToggleButtons = (language: LangType | string) => {
	const dispatch = useDispatch();

	const toggleButtons: ToggleButtonInterface[] = [
		{
			key: 'calls',
			value: 'calls',
			onClick: () => dispatch(searchSlice.actions.setMode('SEARCH')),
			pathName: 'calls',
			title: translate('calls', language),
			isForProd: true,
		},
		{
			key: 'reports',
			value: 'reports',
			onClick: () => {},
			pathName: 'reports',
			title: translate('reports', language),
			withSelect: true,
			isForProd: true,
		},
		{
			key: 'tags',
			value: 'tags',
			onClick: () => {},
			pathName: 'tags',
			title: translate('markupRules', language),
			isForProd: true,
		},
		{
			key: 'notifications',
			value: 'notifications',
			onClick: () => {},
			pathName: 'notifications',
			title: translate('notifications', language),
			isForProd: !EnvsManager.isDev,
		},
		{
			key: 'settings',
			value: 'settings',
			onClick: () => {},
			pathName: 'settings',
			title: translate('settings', language),
			isForProd: true,
		},
	];
	return toggleButtons;
};

export default useToggleButtons;
