import { CriteriasType } from 'store/search/search.types';
import { criteriaWithComplexValues } from 'configs/searchCriteria';
import { SortedCriteriasListType } from '../CriteriasList';
import { Logic } from '../../Selects/MultiValueSelect/components/MultiSelectWithConditions/MultiSelectWithConditions';
import CriteriaWithConditions from '../CriteriaWithConditions';

const getSortedListItem = (criteria: CriteriasType) => (criteriaWithComplexValues.includes(criteria.key)
	? {
		blockNumber: Number(criteria.block),
		criterias: [],
		complexCriterias: {
			[criteria.key]: [criteria],
		},
	} : {
		blockNumber: Number(criteria.block),
		criterias: [criteria],
		complexCriterias: {},
	});

const getSortedListAgain = (
	indexes: number[],
	sortedList: SortedCriteriasListType[],
) => {
	const sortedListAgain: SortedCriteriasListType[] = [];

	while (indexes.length > 0) {
		const min = Math.min(...indexes);
		const test = sortedList
			.find((criteriasObj) => criteriasObj.blockNumber === min);

		if (test) sortedListAgain.push(test);
		indexes.splice(indexes.indexOf(min), 1);
	}

	return sortedListAgain;
};

export function sortByBlocks(
	activeCriterias: CriteriasType[],
) {
	const indexes: number[] = [];
	const sortedList: SortedCriteriasListType[] = [];

	activeCriterias.forEach((criteria) => {
		let index = 0;
		const test = sortedList.find((criteriasArray) => {
			index = sortedList.indexOf(criteriasArray);
			return criteriasArray.blockNumber === Number(criteria.block);
		});

		if (test) {
			if (['client_phone', 'operator_phone'].find(key => key === criteria.key)) {
				sortedList[index].criterias.unshift(criteria);
			} else if (criteriaWithComplexValues.includes(criteria.key)) {
				sortedList[index].complexCriterias[criteria.key] = sortedList[index].complexCriterias[criteria.key]?.length
					? sortedList[index].complexCriterias[criteria.key].concat(criteria) : [criteria];
			} else {
				sortedList[index].criterias.push(criteria);
			}
		} else {
			sortedList.push(getSortedListItem(criteria));
			indexes.push(Number(criteria.block));
		}
	});

	return getSortedListAgain(indexes, sortedList);
}

interface IGetEmptyCriteria extends Pick<
	CriteriaWithConditions, 'block' | 'index' | 'handlerOnChange' | 'isHandlerFunction' | 'page'
> {
	criteria: CriteriasType;
	criteriaItems: CriteriasType[];
	hasConditions: boolean;
}
export const getEmptyCriteria = ({
	criteria,
	criteriaItems,
	block,
	index,
	handlerOnChange,
	isHandlerFunction,
	page,
	hasConditions,
}: IGetEmptyCriteria) => ({
	key: criteria.key,
	selectType: criteria.selectType,
	addMulti: criteria.addMulti,
	values: [],
	wide: criteria.wide,
	groupName: criteria.groupName,
	title: 'no_title',
	criteriaCurrent: criteria,
	block,
	index,
	handlerOnChange,
	isHandlerFunction,
	page,
	hasConditions,
	logic: 'or' as Logic,
	conditionItemId: (criteriaItems[criteriaItems.length - 1].conditionItemId || 0) + 1,
});
