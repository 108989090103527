import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	pagination: {
		margin: '0 16px 0 0',
		'& .MuiPagination-ul': { justifyContent: 'right' },
		'& .MuiPaginationItem-root': {
			borderRadius: '2px',
			margin: '0 4px',
			fontSize: '14px',
			lineHeight: '22px',
			border: '1px solid #CDD5DF',
			color: '#2F3747',
			'&.MuiPaginationItem-ellipsis': {
				border: 'none',
				'&:hover': {
					border: '1px solid transparent',
				},
			},
			'& svg path': {
				fill: '#2F3747',
			},
			'&.Mui-selected': {
				backgroundColor: '#fff ',
				border: '1px solid #722ED1',
				color: '#722ED1',
				'&:hover': {
					backgroundColor: '#fff ',
					border: '1px solid #722ED1',
					color: '#722ED1',
				},
			},
			'&:hover': {
				backgroundColor: '#fff ',
				border: '1px solid #722ED1',
				color: '#722ED1',
			},
			'&.Mui-disabled': {
				background: '#EEF2F6',
				'& svg path': {
					fill: '#A3AEBE',
				},
			},
		},
	},
});
