import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	cbDateItems: {
		display: 'flex',
		margin: '5px 10px 0 0',
	},
	controlBlockDateItem: {
		width: '50%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: '0 0 0 10px',
	},
	calendar: {
		'& .MuiDialog-container': {
			backgroundColor: 'none !important',
			position: 'absolute',
			top: '118px !important',
			left: '148px',
			height: 'auto !important',
			'& .MuiPaper-root': {
				margin: '0',
			},
		},
	},
	calendarInput: {
		'& .MuiInputBase-input': {
			cursor: 'pointer !important',
			width: '100px !important',
			marginLeft: '10px',
		},
		'& .MuiInputBase-input.Mui-disabled': {
			WebkitTextFillColor: '#738094',
		},
	},
	controlBlockButtonBox: {
		boxShadow: 'none !important',
		padding: '0 5px',
		marginLeft: '16px',
	},
	controlBlockButton: {
		border: 'none !important',
		transition: '0.4s !important',
		outline: 'none !important',
		height: '40px',
		fontSize: '14px !important',
		padding: '8px 16px !important',
		// @ts-ignore
		textTransform: 'none !important',
		color: '#738094 !important',
		backgroundColor: '#ffffff !important',
		whiteSpace: 'nowrap',
		'&.Mui-selected': {
			backgroundColor: '#D6D9DF !important',
			color: '#000 !important',
		},
	},
});
