import { FC, ReactElement } from 'react';
import { CircularPreloader } from './index';

interface WithCircularPreloaderProps {
	loading: boolean;
	children: ReactElement;
	message?: string;
	className?: string;
	classNameIcon?: string;
}

const WithCircularPreloader: FC<WithCircularPreloaderProps> = ({ loading, children, message, className, classNameIcon }) => {
	if (loading)
		return (
			<div className={className}>
				{message && <div style={{ marginBottom: '20px' }}>{message}</div>}
				<CircularPreloader className={classNameIcon} />
			</div>
		);

	return children;
};

export default WithCircularPreloader;
