import React, { FC } from 'react';
import { Button } from '@mui/material';
import cn from 'classnames';
import { PlusSvg } from '../Plus';

import s from './AddButton.module.scss';

interface AddButtonProps {
	text: string;
	handleClick: () => void;
	className?: string;
	testId?: string;
}
const AddButton: FC<AddButtonProps> = ({ text, handleClick, className, testId }) => (
	<Button
		className={cn(s.root, className)}
		startIcon={<PlusSvg fill="#722ED1" />}
		onClick={handleClick}
		data-testid={testId || ''}
	>
		{text}
	</Button>
);

export default AddButton;
