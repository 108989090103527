import React, { FC } from 'react';

interface IHighlightedLabel {
	text: string;
	highlight: string,
	id: string
}

const HighlightedLabel: FC<IHighlightedLabel> = ({ text, highlight, id }) => {
	const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

	return (
		<span>{parts.map((part, i) => (
			<span
				key={`${text}-${i}-${id}`} // eslint-disable-line react/no-array-index-key
				style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}
			>
				{part}
			</span>
		))}
		</span>
	);
};

export default HighlightedLabel;
