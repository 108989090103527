/* eslint-disable */
import { FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ReactAudioPlayer from 'react-audio-player';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { IconButton } from 'components/common';

const useStyles = makeStyles({
	audioPlayer: {
		width: '100% !important',
		height: '56px !important',
		margin: '10px 0',
	},
	playerLineBlock: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#F8FAFC',
	},
	playerRateBlock: {
		marginLeft: '10px',
		marginBottom: '10px',
		display: 'flex',
		alignItems: 'center',
	},
	rateValue: {
		width: '30px',
		marginLeft: '5px',
		fontSize: '13px',
	},
	rateValueTitle: {
		position: 'absolute',
		top: '10px',
		fontSize: '10px',
	},
	speedIcon: {
		color: '#738094',
		fontWeight: '700',
	},
	speedIconX: {
		marginLeft: '1px',
		fontSize: '10px',
	},
});

type AudioPlayerPropsType = {
	callAudio: string | undefined | null;
	onListen: (e: any) => void; // TROFIM - any remove
	audioPlayerRef: any; // TROFIM - any remove
	callId: string;
};

const AudioPlayer: FC<AudioPlayerPropsType> = ({ callAudio, onListen, audioPlayerRef }) => {
	const classes = useStyles();
	const { language } = useAppSelector((state) => state.lang);

	const speedUnits = [0.5, 1, 1.5, 2];
	const [currentSpeedUnit, setCurrentSpeedUnit] = useState<number>(speedUnits[1]);
	const speedChanger = (speed: number): void => {
		audioPlayerRef.current.audioEl.current.playbackRate = speed;
	};

	const speedAction = (): void => {
		let speedIndex = speedUnits.indexOf(currentSpeedUnit) + 1;
		if (speedIndex > speedUnits.length - 1) {
			speedIndex = 0;
		}
		speedChanger(speedUnits[speedIndex]);
		setCurrentSpeedUnit(speedUnits[speedIndex]);
	};

	return (
		<div tabIndex={1} className={classes.playerLineBlock}>
			<ReactAudioPlayer
				ref={audioPlayerRef}
				className={classes.audioPlayer}
				controls={true}
				src={callAudio ? callAudio : ''}
				listenInterval={1}
				onListen={onListen}
				onSeeked={onListen}
				preload="metadata"
				autoPlay={true}
			/>
			{/* Скорость */}
			<IconButton
				margin={'0 0 0 8px'}
				backgroundColor="#EEF2F6"
				disabled={false}
				icon={
					<div className={classes.speedIcon}>
						{currentSpeedUnit}
						<span className={classes.speedIconX}>x</span>
					</div>
				}
				onClick={async (): Promise<void> => {
					await speedAction();
				}}
				tooltipTitle={`${translate('callSpeed', language)}`}
				tooltipPlacement="top"
			/>
		</div>
	);
};

export default AudioPlayer;
