import * as React from 'react';
import { FC, memo } from 'react';
import cn from 'classnames';
import Select, { components, ControlProps, GroupBase } from 'react-select';
import { useMuiContainedSelectStyles, createTextSelectStyles } from './ContainedSelect.jss';

export type ContainedSelectPropsType = {
	width: string;
	height?: string;
	justify?: string;
	marginRight?: string;
	padding?: string;
	isSearchable?: boolean;
	placeholder?: string;
	onSelectChange: (event: any) => void;
	options: { value: string; label: string }[];
	value: any;
	disabled?: boolean;
	className?: string;
	controlStyles?: Record<string, string>;
	customStyleProps?: any;
	valueContainerStyles?: any;
	indicatorStyles?: any;
	testId?: string;
};

const Option = memo((props: any) => {
	const newProps = {
		...props,
		innerProps: { ...props.innerProps, onMouseOver: null, onMouseDown: null, onMouseMove: null },
	};
	return <components.Option {...newProps}>{props.children}</components.Option>;
});

const Control = (props: ControlProps<any, false, GroupBase<any>>) => <components.Control {...props} />;

const ContainedSelect: FC<ContainedSelectPropsType> = ({
	width,
	height,
	justify,
	marginRight,
	padding,
	isSearchable,
	placeholder,

	onSelectChange,
	options,
	value,
	disabled,
	className,
	customStyleProps = {},
	controlStyles,
	valueContainerStyles,
	indicatorStyles,
	testId,
}) => {
	const classes = useMuiContainedSelectStyles({
		width,
		justify,
		marginRight,
	});

	const customStyles = createTextSelectStyles({
		width,
		height,
		padding,
		controlStyles,
		valueContainerStyles,
		indicatorStyles,
	});

	const styles = {
		...customStyles,
		...customStyleProps,
	};

	return (
		<div className={cn(classes.selectBox, className)} data-testid={testId}>
			<Select
				menuPlacement="auto"
				openMenuOnFocus
				name="color"
				placeholder={placeholder || ''}
				onChange={onSelectChange}
				isClearable
				closeMenuOnSelect
				styles={styles}
				isSearchable={Boolean(isSearchable)}
				value={value}
				options={options}
				hideSelectedOptions={false}
				components={{
					Option,
					IndicatorSeparator: () => null,
					ClearIndicator: () => null,
					Control,
				}}
				isDisabled={disabled}
			/>
		</div>
	);
};

export default ContainedSelect;
