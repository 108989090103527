import { FC } from 'react';
import { CustomSwitch } from 'shared/ui';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import s from './styles.module.scss';

interface ToggleFormItemProps {
	isChecked: boolean;
	label: string;
	onChecked: (e: any) => void;
}

const ToggleFormItem: FC<ToggleFormItemProps> = ({ isChecked, label, onChecked }) => {
	const { lang } = useParams<RouteParams>();

	return (
		<div className={s.toggleItem}>
			<span>{label}</span>
			<CustomSwitch checked={isChecked} onChecked={onChecked} />
		</div>
	);
};

export default ToggleFormItem;
