import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';

type BoxPropsType = {
	width?: string;
	padding?: string;
	borderRadius?: string;
	border?: string;
	height?: string;
	minHeight?: string;
	margin?: string;
	bcColor?: string;
	boxShadow?: string;
	overflow?: string;
	className?: string;
};

const BlockBox: FC<BoxPropsType> = ({
	bcColor,
	margin,
	width = '100%',
	padding = '0',
	border,
	borderRadius = '10px',
	height = '100%',
	minHeight,
	boxShadow,
	overflow,
	children,
	className,
}) => {
	const useStyles = makeStyles({
		boxWrapper: {
			backgroundColor: bcColor || '#FFFFFF',
			margin: margin || '0px',
			width,
			height,
			minHeight,
			padding,
			border,
			borderRadius,
			boxShadow,
			overflow,
		},
	});
	const classes = useStyles();
	return <div className={cn(classes.boxWrapper, className)}>{children}</div>;
};

export default BlockBox;
