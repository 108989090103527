import React, { FC } from 'react';
import cn from 'classnames';
import { Tooltip as TooltipCustom } from 'components/common';
import { IconQuestion } from '../Criterias/CriteriasList';

import s from './Hint.module.scss';

interface HintProps {
	title: string;
	label?: string;
	className?: string;
}
const Hint: FC<HintProps> = ({
	label,
	title,
	className,
}) => (
	<TooltipCustom
		background="dark"
		title={title}
		placement="right"
		followCursor={false}
	>
		<div className={cn(s.question__wrapper, className)}>
			<IconQuestion />
			{label && (
				<div className={s.question__title}>
					{label}
				</div>
			)}
		</div>
	</TooltipCustom>
)

export default Hint;
