/* eslint-disable */
import React, { FC } from 'react';

import { Radar, RadarChart, PolarGrid, Tooltip, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useAppSelector } from '../../../hooks/redux';

import { RootState } from '../../../store/store';
import { translate } from '../../../localizations';

type ChartRadarPropsType = {
	data: {}[];
	width: number;
	height: number;
	dataKey: string;
	checkChart: string;
};
const useStyles = makeStyles({
	chartList: {
		display: 'flex',
		flexWrap: 'wrap',
		marginBottom: '0',
		justifyContent: 'center',
		marginTop: '16px',
	},
	chartListItem: {
		display: 'flex',
		alignItems: 'center',
		marginRight: '10px',
	},
	chartTooltip: {
		background: '#fff',
		padding: '16px',
		borderRadius: '10px',
		boxShadow: '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
		width: '300px',
	},
	titleChart: {
		position: 'absolute',
		top: '0px',
		left: '50%',
		transform: 'translate(-50%, 0)',
		fontWeight: '700 !important',
		textAlign: 'center',
		marginBottom: '5px !important',
	},
});

const ChartRadar: FC<ChartRadarPropsType> = ({ data, width, height, dataKey, checkChart }) => {
	const classes = useStyles();
	const { language } = useAppSelector((state: RootState) => state.lang);
	const CustomizedTooltip = ({ active, payload, label }: any) => {
		if (active && payload && payload.length) {
			return (
				<div className={classes.chartTooltip}>
					<div style={{ marginBottom: '8px' }}>
						<span>{payload[0].payload.idName}</span>:
						<span style={{ paddingLeft: '10px', color: `${payload[0].fill}`, whiteSpace: 'nowrap' }}>
							{checkChart === 'seconds' || checkChart === 'percent'
								? checkChart === 'seconds'
									? moment.utc(payload[0].value * 1000).format('HH:mm:ss')
									: `${payload[0].value} %`
								: payload[0].value}
						</span>
					</div>
				</div>
			);
		}
		return null;
	};
	return (
		<div style={{ position: 'relative' }}>
			<Typography className={classes.titleChart}>{translate('reportAll', language)}</Typography>
			<ResponsiveContainer width="100%" height={height}>
				<RadarChart
					cx="50%"
					cy="50%"
					outerRadius="80%"
					data={data}
					margin={{
						top: 19,
						right: 20,
						left: 20,
						bottom: 5,
					}}
					style={{ fontSize: '12px' }}
				>
					<PolarGrid />
					{data.length < 30 ? <PolarAngleAxis dataKey="idName" style={{ fontSize: '11px' }} /> : <></>}
					{checkChart === 'seconds' ? (
						<PolarRadiusAxis tickFormatter={(timeStr) => moment.utc(timeStr * 1000).format('HH:mm:ss')} />
					) : (
						<PolarRadiusAxis />
					)}
					<Radar name="radar" dataKey={dataKey} stroke="#9254DE" fill="#B37FEB" fillOpacity={0.6} />
					<Tooltip wrapperStyle={{ zIndex: 100 }} content={<CustomizedTooltip />} />
				</RadarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default ChartRadar;
