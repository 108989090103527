/* eslint-disable */
import { FC, useEffect, useRef, useState } from 'react';
import { ActionMeta } from 'react-select/dist/declarations/src/types';
import { MultiValue } from 'react-select';
import isEqual from 'lodash/isEqual';
import { useSelectAll, useOnClickOutside } from 'hooks';
import { getValuesForComplexSelect } from 'utils/convertDataForSearchFilters/convertData';

import { useMuiCustomSelectStyles } from './MultiValueSelect.jss';
import MultiSelectWithCreate from './components/MultiSelectWithCreate';
import MultiSelectFromOptions from './components/MultiSelectFromOptions';
import { IMultiValueSelect, OptionType } from './multiValuesSelect';
import MultiSelectWrapper from './components/MultiSelectWrapper';
import MultiSelectWithConditions from './components/MultiSelectWithConditions/MultiSelectWithConditions';
import { getSelectStyles } from './utils';
import MultiSelectWithSearchCriteria from './components/MultiSelectWithSearchCriteria';

const MultiValueSelect: FC<IMultiValueSelect> = ({
	value,
	options,
	selectType,
	valueHandler,
	title,
	help,
	placeholder,
	width,
	height,
	backgroundColor,
	border,
	color,
	selectAll = true,
	selectAllName = '',
	hasConditions,
	logic,
	conditionItemId,
	criteriaKey,
}) => {
	const [inputValue, setInputValue] = useState<string | null>(null);
	const [customValues, setCustomValues] = useState<OptionType[]>([]);
	const selectRef = useRef<HTMLDivElement | null>(null);
	const classes = useMuiCustomSelectStyles({ width });

	const { allOptions, inputSelectedValues, localValue, methods } = useSelectAll(
		options,
		selectAll,
		selectAllName,
		customValues,
	);

	const { setLocalValue, isOptionSelected, getCustomOptions, handleSelectClick } = methods;

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	const onClickOutside = (): void => {
		if (inputValue && !localValue.find((val) => val.value === inputValue)) {
			const isEqualValue = isEqual(value, [...localValue, { value: inputValue, label: inputValue }]);
			if (!isEqualValue) {
				valueHandler([...localValue, { value: inputValue, label: inputValue }], logic, conditionItemId);
			}
		} else {
			const isEqualValue = isEqual(value, localValue);
			if (!isEqualValue) {
				valueHandler(localValue, logic, conditionItemId);
			}
		}
	};

	const onOptionSelect = (newValues: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>): void => {
		const { action, option, removedValue } = actionMeta;

		if (!newValues.length) valueHandler([]);

		const customVals: OptionType[] = getCustomOptions(newValues);

		handleSelectClick({
			newValues,
			action,
			option,
			removedValue,
			customVals,
		});
		setCustomValues(customVals);
		setInputValue(null);
	};

	const onInputChange = (val: string): void => {
		setInputValue(val);
	};

	const onCreateOption = (inputOption: string) => {
		setLocalValue((prev) => [
			...prev,
			{
				value: inputOption,
				label: inputOption,
			},
		]);
	};

	const selectStyles = getSelectStyles(selectType);

	const renderMultiSelect = (type: string) => {
		let comp;
		switch (type) {
			case 'complex':
				comp = hasConditions ? (
					<MultiSelectWithConditions
						criteriaKey={criteriaKey}
						value={getValuesForComplexSelect(value, criteriaKey)}
						height={height}
						placeholder={placeholder}
						valueHandler={valueHandler}
						logic={logic}
						conditionItemId={conditionItemId}
					/>
				) : (
					<MultiSelectWithSearchCriteria
						criteriaKey={criteriaKey}
						value={getValuesForComplexSelect(value, criteriaKey)}
						height={height}
						placeholder={placeholder}
						valueHandler={valueHandler}
						conditionItemId={conditionItemId}
					/>
				);
				break;
			case 'multiString':
				comp = (
					<MultiSelectWithCreate
						value={localValue}
						options={options}
						isOptionSelected={isOptionSelected}
						onOptionSelect={onOptionSelect}
						onInputChange={onInputChange}
						onCreateOption={onCreateOption}
						height={height}
						backgroundColor={backgroundColor}
						border={border}
						color={color}
						placeholder={placeholder}
					/>
				);
				break;
			default:
				comp = (
					<MultiSelectFromOptions
						value={inputSelectedValues}
						options={allOptions}
						isOptionSelected={isOptionSelected}
						onOptionSelect={onOptionSelect}
						placeholder={placeholder}
						valueHandler={valueHandler}
						localValue={localValue}
					/>
				);
				break;
		}
		return comp;
	};

	useOnClickOutside(selectRef, onClickOutside);

	return (
		<div ref={selectRef} className={classes.selectBox}>
			<MultiSelectWrapper
				selectComp={renderMultiSelect(selectType)}
				rootClassName={classes.selectSelectBox}
				tileClassName={selectStyles.tileClassName}
				wrapperClassName={selectStyles.wrapperClassName}
				title={title}
				help={help}
			/>
		</div>
	);
};

export default MultiValueSelect;
