/* eslint-disable */
import { FC, useEffect, useState, memo } from 'react';
import Select, { components } from 'react-select';
import { useDispatch } from 'react-redux';
import { useMuiTextSelectStyles, createTextSelectStyles } from './TextSelect.jss';
import { CrossSvgWithBg, OnBottomArrow, OnTopArrow, PlusSvg, MinusSvg } from './TextSelect.svg';
import { searchSlice } from '../../../../store';

// TYPES BLOCK
type SelectValueFormat = {
	value: any | null;
	label: string | null;
};

type TextSelectPropsType = {
	value: SelectValueFormat | null;
	handleValueChange: (event: any) => void;
	options: SelectValueFormat[];

	iconPosition?: 'left' | 'right';
	icon?: any;

	customControl: any;

	notClose?: boolean;
	menuPosition: 'left' | 'right';

	width?: string;
	height?: string;
	margin?: string;
	ifArrowColor?: string;
	optionIcon?: 'plus' | 'minus';

	name: string;
};

const TextSelect: FC<TextSelectPropsType> = ({
	value,
	handleValueChange,
	options,
	icon,
	iconPosition,
	optionIcon,
	customControl,
	notClose,
	menuPosition,
	width,
	height,
	ifArrowColor,

	name,
}) => {
	const customSelectStyles = createTextSelectStyles({ width, menuPosition });
	const classes = useMuiTextSelectStyles({ arrowColor: ifArrowColor });
	const dispatch = useDispatch();

	// COMPONENTS BLOCK
	const Arrow = memo(() => {
		if (isMenuOpen) {
			return (
				<div className={classes.selectArrowOnTopBox}>
					<OnTopArrow className={classes.selectArrow} />
				</div>
			);
		}
		return <OnBottomArrow className={classes.selectArrow} />;
	});
	const Control: FC<any> = ({ children, ...props }) => (
		<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', margin: '2px' }}>
			<div style={{ display: 'flex', alignItems: 'center' }} onClick={handleSelectClick}>
				{iconPosition === 'left' ? icon || <Arrow /> : null}
				{customControl}
				{iconPosition === 'right' ? icon || <Arrow /> : null}
			</div>
			{name === 'templatesSelect' && value && (
				<CrossSvgWithBg
					style={{ fill: '#722ED1', cursor: 'pointer', marginLeft: '15px' }}
					onClick={() => {
						dispatch(searchSlice.actions.setCurrentTemplate(null));
						dispatch(searchSlice.actions.removeAllActiveCriterias({ page: 'calls', data: null }));
					}}
				/>
			)}
		</div>
	);
	const CustomMenuList = memo(({ selectProps, ...props }: any) => (
		<div style={{ height: `${height || 'auto'}` }}>{props.children}</div>
	));
	const CustomOption: FC<any> = ({ children, ...props }) => (
		<components.Option {...props}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					cursor: 'pointer',
				}}
			>
				{children}
				{optionIcon === 'plus' && <PlusSvg />}
				{optionIcon === 'minus' && <MinusSvg />}
			</div>
		</components.Option>
	);

	// LOGIC BLOCK

	// выполняется когда меняется значение.
	const handleSelectChange = (event: any) => {
		if (notClose) {
			handleValueChange(event);
		} else {
			handleValueChange(event);
			setIsMenuOpen(false);
		}
	};

	// выполняется клике на селекта.
	const handleSelectClick = () => {
		setIsMenuOpen(true);
	};

	// открыте и закрытие менюшки.
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	// костылечек для загрытия менюшки.
	useEffect(() => {
		document.addEventListener('mousedown', () => setIsMenuOpen(false));
		return () => {
			document.removeEventListener('mousedown', () => setIsMenuOpen(false));
		};
	}, []);

	return (
		<div className={classes.selectBox}>
			<Select
				styles={customSelectStyles}
				options={options}
				value={value}
				openMenuOnFocus
				menuIsOpen={isMenuOpen}
				isClearable
				closeMenuOnSelect={false}
				isSearchable={false}
				hideSelectedOptions={false}
				onChange={handleSelectChange}
				components={{
					IndicatorsContainer: () => null,
					Control,
					MenuList: CustomMenuList,
					Option: CustomOption,
				}}
			/>
		</div>
	);
};

export default TextSelect;
