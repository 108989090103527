import React, { Dispatch, FC, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Fab, Tooltip } from '@mui/material';

type TooltipPlacementType =
	| 'bottom-end'
	| 'bottom-start'
	| 'bottom'
	| 'left-end'
	| 'left-start'
	| 'left'
	| 'right-end'
	| 'right-start'
	| 'right'
	| 'top-end'
	| 'top-start'
	| 'top';

type IconButtonPropsType = {
	onClick?: (event: any, setLoading: Dispatch<boolean>) => void;
	disabled?: boolean;
	icon: any;
	backgroundColor?: string;
	borderRadius?: string;
	margin?: string;
	width?: string;
	height?: string;
	mainLoading?: boolean;
	tooltipTitle?: string;
	tooltipPlacement?: TooltipPlacementType;
	buttonStyles?: Record<string, string>;
};

type IconButtonInnerPropsType = {
	loading: boolean;
	setLoading: Dispatch<boolean>;
	disabled?: boolean;
	mainLoading?: boolean;
	icon: any;
	classes: any;
	onClick?: (event: any, setLoading: Dispatch<boolean>) => void;
};

const IconButtonInner: FC<IconButtonInnerPropsType> = ({
	loading,
	setLoading,
	disabled,
	mainLoading,
	icon,
	classes,
	onClick,
}) => (
	<Fab
		disabled={Boolean(disabled) || Boolean(loading)}
		className={classes.button}
		onClick={async (event) => {
			if (onClick) {
				setLoading(true);
				onClick(event, setLoading);
				setLoading(false);
			}
		}}
	>
		{loading || mainLoading ? <CircularProgress style={{ color: '#738094' }} size={13} /> : icon}
	</Fab>
);

const IconButton: FC<IconButtonPropsType> = ({
	// logic
	onClick,
	disabled,
	mainLoading,
	// styles
	icon,
	backgroundColor,
	borderRadius,
	margin,
	width,
	height,

	// tooltip params
	tooltipTitle,
	tooltipPlacement,

	buttonStyles,
}) => {
	const useButtonStyles = makeStyles({
		button: {
			'&.MuiButtonBase-root.MuiFab-root': {
				opacity: disabled ? '0.38' : '1',
				minWidth: '32px',
				padding: '0 8px',
				width: width || '32px',
				height: height || '32px',
				minHeight: '32px',
				borderRadius: borderRadius || '5px',
				boxShadow: 'none',
				margin: margin || '0',
				backgroundColor: backgroundColor || 'none',
				zIndex: '1',
				border: backgroundColor ? '1px solid #E3E8EF' : 'none',
				...buttonStyles,
			},
		},
		tooltip: {
			fontFamily: 'Inter, sans-serif !important',
			color: '#000 !important',
			backgroundColor: '#E3E8EF !important',
		},
	});

	const classes = useButtonStyles();
	const [loading, setLoading] = useState(false);

	return (
		<Tooltip title={tooltipTitle || ''} placement={tooltipPlacement} classes={{ tooltip: classes.tooltip }}>
			<div>
				<IconButtonInner
					loading={loading}
					setLoading={setLoading}
					disabled={disabled}
					mainLoading={mainLoading}
					icon={icon}
					classes={classes}
					onClick={onClick}
				/>
			</div>
		</Tooltip>
	);
};

export default IconButton;
