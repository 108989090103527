import React, { FC } from 'react';
import { IconButton } from 'components/common';

type TimePropsType = {
	side: 'left' | 'right';
	value: number;
};

// конвертирует время для показа
export const timeConverter = (s: number, hoursIsDisplay: boolean) => {
	const pad = (n: number) => `00${n}`.slice(-2);
	const ms = s % 1000;
	s = (s - ms) / 1000;
	const secs = s % 60;
	s = (s - secs) / 60;
	const mins = s % 60;
	const hrs = (s - mins) / 60;
	if (hoursIsDisplay) {
		return `${pad(hrs)}:${pad(mins)}:${pad(secs)}`;
	}
	return `${pad(mins)}:${pad(secs)}`;
};

const Time: FC<TimePropsType> = ({ side, value }) => (
	<IconButton
		margin="12px 16px"
		backgroundColor="#F8FAFC"
		width="auto"
		disabled
		icon={<div style={{ color: side === 'left' ? '#A3AEBE' : '#738094' }}>{timeConverter(value, false)}</div>}
		onClick={() => {}}
	/>
);

export default Time;
