import React, { FC } from 'react';
import { CriteriaBlockPage, CriteriasType } from 'store/search/search.types';
import cn from 'classnames';
import { SimpleButton } from 'components/common';
import { DeleteSvg } from 'components/Icons/Reports/DeleteSvg';
import CriteriaItem from '../CriteriaItem';
import { CustomValueHandler, HandlerOnChange, Page } from '../CriteriasList';
import { getStyles } from '../Criterias.jss';
import { useCriteriaStyles } from '../hooks/useCriteriaStyles';

interface ICriteriaConditions {
	criteriaItems: CriteriasType[];
	allCriterias: CriteriasType[];
	isSingleCondition: boolean;
	handlerOnChange?: HandlerOnChange;
	isHandlerFunction?: boolean | undefined;
	handleDelete: (item: CriteriasType) => void;
	page: Page;
	block?: CriteriaBlockPage;
	customValueHandler?: CustomValueHandler;
}
const CriteriaConditions: FC<ICriteriaConditions> = ({
	criteriaItems,
	allCriterias,
	isSingleCondition,
	handlerOnChange,
	isHandlerFunction,
	handleDelete,
	page,
	block,
	customValueHandler,
}) => {
	const { getBlockSearchClassName } = useCriteriaStyles();
	const { criteriaItem, criteriaItemWrapper, deleteButton } = getStyles();

	return (
		<>
			{criteriaItems.map(criteria => {
				const compResult = allCriterias.filter(v => v.key === criteria.key)[0];
				const singleCriteriaLogic = criteria.values.length ? criteria.logic : 'and';
				const criteriaLogic = isSingleCondition ? singleCriteriaLogic : criteria.logic;
				const criteriaHasConditions = isSingleCondition
					? criteriaLogic !== undefined && criteriaLogic !== 'and' : true;

				return (
					<div
						className={cn(getBlockSearchClassName(criteria.block), {
							[criteriaItemWrapper]: !isSingleCondition,
						})}
						key={criteria.conditionItemId}
					>
						<CriteriaItem
							className={cn({
								[criteriaItem]: !isSingleCondition || page === 'markup',
							})}
							conditionItemId={criteria.conditionItemId}
							title="no_title"
							criteriaFull={compResult}
							criteriaCurrent={criteria}
							block={block}
							logic={criteriaLogic}
							handlerOnChange={handlerOnChange}
							isHandlerFunction={isHandlerFunction}
							page={page}
							hasConditions={criteriaHasConditions}
							customValueHandler={customValueHandler}
						/>
						{!isSingleCondition && (
							<SimpleButton
								rootClassName={deleteButton}
								icon={<DeleteSvg style={{ width: '19px', height: '19px' }} />}
								onClick={() => handleDelete(criteria)}
							/>
						)}
					</div>
				);
			})}
		</>
	);
};

export default CriteriaConditions;
