/* eslint-disable */
import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { reportsSlice } from 'store';
import { ContainedSelect } from 'components/common';
import { translate } from 'localizations';

import ChartBar from './ChartBar';
import styles from './style.module.scss';
import ChartRadar from './ChartRadar';
import ChartPie from './ChartPie';
import ChartArea from './ChartArea';

export const colors = [
	'#B37FEB',
	'#95DE64',
	'#FFA940',
	'#FF4D4F',
	'#597EF7',
	'#FF85C0',
	'#36CFC9',
	'#F759AB',
	'#FF9C6E',
];

interface ChartsBlockPropsType {
	chartTypeOptions: {
		label: string;
		value: string;
	}[];
	tableRows: {}[];
	dataChart: [];
	handleCheckChart: (event: React.ChangeEvent<HTMLInputElement>) => void;
	checkChart: string;
	dataChartLegend: string[];
	dataLegendLabelX: string;
	dataLegendLabelY: string;
	dataChartAll: string;
	language: string;
	checkboxShowChart: boolean;
	chartType: { value: string; label: string };
}

const ChartsBlock: FC<ChartsBlockPropsType> = React.memo(
	({
		chartTypeOptions,
		tableRows,
		dataChart,
		handleCheckChart,
		checkChart,
		dataChartLegend,
		dataLegendLabelX,
		dataLegendLabelY,
		dataChartAll,
		language,
		checkboxShowChart,
		chartType,
	}) => {
		const dispatch = useDispatch();

		const WIDTH = 800;
		const HEIGHT = 320;
		const HEIGHT2 = 350;

		let isMounted = true;

		useEffect(() => {
			if (!chartType.value && isMounted) dispatch(reportsSlice.actions.setChartType(chartTypeOptions[0]));
			return () => {
				isMounted = false
			};
		}, [chartType]);

		useEffect(() => {
			const translateChartType = chartTypeOptions.find((item) => item.value === chartType.value);
			if (translateChartType) dispatch(reportsSlice.actions.setChartType(translateChartType));
		}, [language]);

		return (
			<div className={styles.chartFlex}>
				<div className={styles.charts}>
					{chartType.value === 'barChart' && (
						<div className={styles.chart}>
							<ChartBar
								data={tableRows}
								width={WIDTH}
								height={320}
								dataKey={dataChart}
								checkChart={checkChart}
								dataChartLegend={dataChartLegend}
								dataLegendLabelX={dataLegendLabelX}
								dataLegendLabelY={dataLegendLabelY}
							/>
						</div>
					)}
					{chartType.value === 'lineChart' && (
						<div className={styles.chart}>
							<ChartArea
								data={tableRows}
								width={WIDTH}
								height={HEIGHT}
								dataKey={dataChart}
								checkChart={checkChart}
								dataChartLegend={dataChartLegend}
								dataLegendLabelX={dataLegendLabelX}
								dataLegendLabelY={dataLegendLabelY}
							/>
						</div>
					)}
					{chartType.value === 'pieChart' && (
						<div className={styles.chartWidth}>
							<ChartPie
								data={tableRows}
								checkChart={checkChart}
								width={WIDTH}
								height={HEIGHT2}
								dataKey={dataChartAll}
							/>
						</div>
					)}
					{chartType.value === 'radarChart' && (
						<div className={styles.chartWidth}>
							<ChartRadar
								data={tableRows}
								checkChart={checkChart}
								width={WIDTH}
								height={HEIGHT2}
								dataKey={dataChartAll}
							/>
						</div>
					)}
				</div>
				<div className={styles.radiosBlock}>
					<FormGroup>
						<FormControl>
							<FormLabel id="radio-buttons-group" className={styles.radioButton}>
								{translate('reportShow', language)}
							</FormLabel>
							<RadioGroup
								aria-labelledby="radio-buttons-group"
								defaultValue="calls"
								name="radio-buttons-group"
								onChange={handleCheckChart}
								value={checkChart}
							>
								<FormControlLabel
									value="calls"
									control={<Radio />}
									label={`${translate('reportCalls', language)}`}
								/>
								<FormControlLabel
									value="seconds"
									control={<Radio />}
									label={`${translate('reportsTime', language)}`}
								/>
								<FormControlLabel
									value="percent"
									control={<Radio />}
									label={`${translate('reportPercent', language)}`}
								/>
							</RadioGroup>
						</FormControl>
					</FormGroup>

					<FormGroup className={styles.formGroup}>
						<FormControl>
							<FormLabel id="radio-buttons-group" className={styles.radioLabel}>
								{translate('reportTypeGraph', language)}
							</FormLabel>
							{checkboxShowChart && (
								<div>
									<ContainedSelect
										height="38px"
										width="265px"
										justify="flex-start"
										onSelectChange={(event): void => {
											dispatch(reportsSlice.actions.setChartType(event));
										}}
										options={chartTypeOptions}
										value={chartType}
									/>
								</div>
							)}
						</FormControl>
					</FormGroup>
				</div>
			</div>
		);
	},
);

export default ChartsBlock;
