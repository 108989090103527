import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

type useMuiInputStylesProps = {
	bcColor?: string;
	height?: string;
	border?: string;
};

export const useMuiInputStyles = makeStyles<Theme, useMuiInputStylesProps>((theme: Theme) => ({
	inputRoot: {
		backgroundColor: (props) => props.bcColor,
		height: (props) => `${props.height ? props.height : '33px'} !important`,
		borderRadius: '5px',
		width: '100%',
		border: (props) => (props.border ? `${props.border} !important` : 'none !important'),
		'& .MuiInputLabel-root': {
			cursor: 'text !important',
			color: '#738094 !important',
			fontSize: '14px !important',
			left: '-5px',
			top: '-10px',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
			padding: '8px 28px 8px 10px !important',
			color: '#738094',
		},
	},
}));
