import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CircularPreloader: FC<{className?: string}> = ({ className }) => (
	<Box
		className={className}
		sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
	>
		<CircularProgress />
	</Box>
);

export default CircularPreloader;
