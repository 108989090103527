import React, { FC, ReactNode, useState } from 'react';
import cn from 'classnames';
import { InputBase } from '@mui/material';
import Field from 'components/common/Field/Field';
import { EyeClosedIcon, EyeOpenIcon } from 'shared/ui';
import './NewInput.css';

type InputPropsTypes = {
	title?: string;
	placeholder?: string;
	inputType?: string;
	value?: string | number | null;
	handleChange?: (e: React.ChangeEvent<any>) => void;
	name?: string;
	width?: string;
	margin?: string;
	disabled?: boolean;
	required?: boolean;
	borderColor?: string;
	className?: string;
	readOnly?: boolean;
	dataTestid?: string | number | null;
	autoComplete?: string;
	classNameLabel?: string;
	autoFocus?: boolean;
	isPassword?: boolean;
	labelBrother?: ReactNode;
	classNameWrapper?: string;
};

const Input: FC<InputPropsTypes> = ({
	title,
	inputType = 'text',
	placeholder,
	value,
	handleChange,
	name,
	width,
	margin,
	disabled,
	required,
	borderColor,
	className,
	readOnly,
	dataTestid,
	autoComplete,
	classNameLabel,
	autoFocus = false,
	isPassword = false,
	labelBrother,
	classNameWrapper,
}) => {
	const [passwordShown, setPasswordShown] = useState(false);
	const togglePassword = (): void => {
		setPasswordShown(!passwordShown);
	};

	const withPassword = passwordShown ? inputType : 'password';
	const type = isPassword ? withPassword : inputType;

	return (
		<div className={cn('input-block', className)}>
			<Field
				classNameRoot="input-root"
				classNameLabel={classNameLabel}
				classNameWrapper={classNameWrapper}
				margin={margin || '0 2% 0 0'}
				label={title}
				height="100%"
				width={width || '100%'}
				borderColor={borderColor || null}
				required={required}
				labelBrother={labelBrother}
			>
				<InputBase
					autoFocus={autoFocus}
					required={required || false}
					disabled={Boolean(disabled)}
					style={{ width: '100%' }}
					type={type}
					className="input"
					name={name || ''}
					placeholder={placeholder}
					value={value || ''}
					onChange={handleChange}
					readOnly={readOnly}
					data-testid={dataTestid}
					autoComplete={autoComplete}
				/>
				{isPassword && (
					<div onClick={togglePassword} className="input-eye">
						{passwordShown ? <EyeOpenIcon pathFill="#738094" /> : <EyeClosedIcon pathFill="#738094" />}
					</div>
				)}
			</Field>
		</div>
	);
};

export default Input;
