import React, { FC, useState } from 'react';
import { CaretIcon } from 'components/Icons/CaretIcon';
import styles from './styles.module.scss';

type AccordeonType = {
	title: React.ReactNode;
	initialState: boolean;
	contentNotMargin?: boolean;
};

const Accordeon: FC<AccordeonType> = ({ title, initialState, contentNotMargin, children }) => {
	const [isOpen, setIsOpen] = useState(initialState);

	return (
		<div className={styles.accordeon}>
			<div className={styles.titleWrapper}>
				<div className={styles.titleLeft}>{title}</div>
				<div
					className={styles.iconRight}
					onClick={() => {
						setIsOpen(!isOpen);
					}}
				>
					<div className={isOpen ? styles.icon : styles.iconUp}>
						<CaretIcon />
					</div>
				</div>
			</div>

			{isOpen && <div className={!contentNotMargin ? styles.content : ''}>{children}</div>}
		</div>
	);
};

export default Accordeon;
