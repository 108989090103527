import React, { FC } from 'react';
import cn from 'classnames';
import { IconButton } from '@mui/material';
import Input from '../Input/AuthInput/Input';
import { SearchSvg } from '../../Icons/SearchSvg';

import s from './SearchInput.module.scss';

interface SearchInputProps {
	value: string;
	label: string;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	classNameRoot?: string;
	classNameBtn?: string;
	testId?: string;
}
const SearchInput: FC<SearchInputProps> = ({
	classNameRoot,
	classNameBtn,
	handleChange,
	value,
	label,
	testId,
}) => (
	<div className={cn(s.searchInput__root, classNameRoot)}>
		<Input
			testId={testId}
			handleChange={handleChange}
			value={value}
			name="searchString"
			type="text"
			height="35px"
			bcColor="#F8FAFC"
			border="1px solid #E3E8EF"
			label={label}
		/>
		<div className={cn(s.searchInput__btn, classNameBtn)}>
			<IconButton type="submit">
				<SearchSvg />
			</IconButton>
		</div>
	</div>
);

export default SearchInput;
