/* eslint-disable indent */
// @ts-nocheck

import 'antd/dist/antd.css';
import { DatePicker, Space, ConfigProvider } from 'antd';
import moment, { Moment } from 'moment';
import localeRu from 'antd/es/locale/ru_RU';
import localeEn from 'antd/es/locale/en_US';
import 'moment/locale/ru';
import 'antd/es/date-picker/style/index';

import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { useStyles } from './DateRangePickerWrapperStyles.jss';

interface getLocaleProps {
	[key: string]: any;
}

const START_DATE_LIMIT = '2021-01-01';

interface DateRangePickerWrapperProps {
	value: [Moment, Moment];
	dateError: string;
	disabled: boolean;
	disabledDate?: any;
	onChange: () => void;
	showTime?: boolean;
	hideRanges?: boolean;
}

const DateRangePickerWrapper: FC<DateRangePickerWrapperProps> = ({
	value,
	dateError,
	disabled,
	disabledDate,
	onChange,
	showTime,
	hideRanges,
}) => {
	const classes = useStyles({ showTime });
	const { language } = useAppSelector((state) => state.lang);

	const { RangePicker } = DatePicker;
	const dateFormat = showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
	const dateFormatList = [dateFormat, 'DD/MM/YYYY'];

	const getLocale: getLocaleProps = {
		ru: localeRu,
		en: localeEn,
	};
	const disabledDateCustom = (current: Moment): boolean =>
		// Can not select days after today (current > moment().endOf('day')) and before date
		current < moment(START_DATE_LIMIT, 'DD/MM/YYYY');
	const getDates = (dates: Date[] | null | null[], shouldShowTime: boolean): [Moment, Moment] | [null, null] => {
		const format = shouldShowTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
		if (dates) {
			if (dates[0] && dates[1]) return [moment(dates[0], format), moment(dates[1], format)];
		}
		return [null, null];
	};

	let ranges;
	if (hideRanges) {
		ranges = undefined;
	} else {
		ranges = {
			[translate('yesterday', language)]: [
				moment().subtract(1, 'days').startOf('day'),
				moment().subtract(1, 'days').endOf('day'),
			],
			[translate('today', language)]: [moment().startOf('day'), moment().endOf('day')],
			[translate('lastWeek', language)]: [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
			[translate('lastMonth', language)]: [
				moment().subtract(1, 'month').add(1, 'day').startOf('day'),
				moment().endOf('day'),
			],
			[translate('lastYear', language)]: [
				moment().subtract(1, 'year').add(1, 'day').startOf('day'),
				moment().endOf('day'),
			],
			[translate('currentWeek', language)]: [moment().startOf('week'), moment().endOf('week')],
			[translate('currentMonth', language)]: [moment().startOf('month'), moment().endOf('month')],
			[translate('currentQuarter', language)]: [moment().startOf('quarter'), moment().endOf('quarter')],
		};
	}

	return (
		<ConfigProvider locale={getLocale[language]}>
			<Space direction="horizontal" size={20} className={classes.wrapperRangePicker}>
				<RangePicker
					defaultValue={getDates(value, showTime)}
					value={getDates(value, showTime)}
					format={dateFormatList}
					status={dateError ? 'error' : ''}
					className={classes.rangePicker}
					disabled={disabled}
					disabledDate={disabledDate || disabledDateCustom()}
					popupClassName={classes.dropdown}
					showTime={showTime ? { format: 'HH:mm' } : false}
					ranges={ranges}
					onChange={onChange}
				/>
			</Space>
		</ConfigProvider>
	);
};

export default DateRangePickerWrapper;
