import { getMultiSelectStyles } from '../MultiValueSelect.jss';

const getGlobalStylesConfig = () => {
	const {
		selectTitleComplex,
		selectWrapperComplex,
		selectTitleMultiString,
		selectWrapperMultiString,
	} = getMultiSelectStyles();

	const stylesConfig: Record<string, {tileClassName: string; wrapperClassName: string}> = {
		complex: {
			tileClassName: selectTitleComplex,
			wrapperClassName: selectWrapperComplex,
		},
		multiString: {
			tileClassName: selectTitleMultiString,
			wrapperClassName: selectWrapperMultiString,
		},
	};

	return stylesConfig;
};

export const getSelectStyles = (type: string) => {
	const config = getGlobalStylesConfig();
	const styles = config[type];

	return styles ?? config.complex;
};
