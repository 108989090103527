import React, { FC, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';
import { translate } from 'localizations';
import { ContainedSelect } from 'components/common';
import MultiSelectWithSearchCriteria from '../MultiSelectWithSearchCriteria';
import { IMultiSelectWithConditions, OptionType } from '../../multiValuesSelect';
import { getSelectStyles } from './multiValueSelect.jss';

export type Logic = 'and' | 'or' | 'not_and' | 'not_or';

export interface ILogicOption extends Pick<OptionType, 'label'> {
	value: Logic;
}

const MultiSelectWithConditions: FC<IMultiSelectWithConditions> = ({
	value,
	valueHandler,
	placeholder,
	height,
	logic,
	conditionItemId,
	criteriaKey,
}) => {
	const { language } = useAppSelector((state: RootState) => state.lang);
	const { selectWithCondition, block } = getSelectStyles();

	const logicOptions: ILogicOption[] = [
		{
			value: 'and',
			label: translate('logicCondition_and', language),
		},
		{
			value: 'or',
			label: translate('logicCondition_or', language),
		},
		{
			value: 'not_and',
			label: translate('logicCondition_not_and', language),
		},
		{
			value: 'not_or',
			label: translate('logicCondition_not_or', language),
		},
	];

	const defaultCondition = logicOptions[0];

	const getInitialCondition = () => {
		let logicCondition: ILogicOption | undefined;
		if (logic) {
			logicCondition = logicOptions.find(item => item.value === logic);
		}

		return logicCondition || defaultCondition;
	};
	const [currentLogic, setCurrentLogic] = useState<ILogicOption>(getInitialCondition());

	const onSelectLogic = ({ value: LogicValue, label }: OptionType) => {
		setCurrentLogic({ value: LogicValue, label });
		valueHandler(value, LogicValue, conditionItemId);
	};

	const customValueHandler = (selectedValues: OptionType[]) => {
		valueHandler(selectedValues, currentLogic.value, conditionItemId);
	};

	return (
		<div className={selectWithCondition}>
			<div className={block}>
				<ContainedSelect
					width="130px"
					marginRight="8px"
					onSelectChange={onSelectLogic}
					options={logicOptions}
					value={currentLogic}
				/>
				<MultiSelectWithSearchCriteria
					criteriaKey={criteriaKey}
					value={value}
					valueHandler={customValueHandler}
					placeholder={placeholder}
					height={height}
				/>
			</div>
		</div>
	);
};

export default MultiSelectWithConditions;
