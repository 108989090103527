import React, { FC, memo } from 'react';
import cn from 'classnames';
import { makeStyles } from '@mui/styles';
import { searchSlice } from 'store/search/search.slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';
import { CriteriaBlockPage, CriteriasType } from 'store/search/search.types';
import { translate } from 'localizations';
import { multiSelectsWithoutDropdown } from 'configs/multiSelectConfig';
import { converter, eventConverter } from 'utils/convertDataForCriteria/convertDataForCriteria';
import { getDataForActiveCriteria } from 'utils/convertDataForSearchFilters/convertData';
import variables from 'styles/variables.scss';
import MultiValueSelect from '../Selects/MultiValueSelect/MultiValueSelect';
import { Logic } from '../Selects/MultiValueSelect/components/MultiSelectWithConditions/MultiSelectWithConditions';
import { CustomValueHandler, HandlerOnChange, Page } from './CriteriasList';
import { OptionType } from '../Selects/MultiValueSelect/multiValuesSelect';
import { useTitleAndPlaceholder } from './hooks/useTitleAndPlaceholder';

const useStyles = makeStyles(({
	searchItem: {
		display: 'block',
		width: '100%',
		alignItems: 'center',
		padding: '0 16px 16px 0',
	},
	searchText: {
		color: `${variables.gray_9} !important`,
		fontSize: '13px !important',
		marginBottom: '5px !important',
	},
}));

interface ICriteriaItem {
	criteriaFull: CriteriasType;
	criteriaCurrent: CriteriasType;
	page: Page;
	isHandlerFunction?: boolean | undefined;
	handlerOnChange?: HandlerOnChange;
	block?: CriteriaBlockPage;
	title?: string;
	className?: string;
	conditionItemId?: number;
	hasConditions?: boolean;
	logic?: Logic;
	customValueHandler?: CustomValueHandler;
}

const CriteriaItem: FC<ICriteriaItem> = memo(({
	criteriaFull,
	criteriaCurrent,
	page,
	block,
	title,
	className,
	conditionItemId,
	hasConditions,
	logic,
	isHandlerFunction,
	handlerOnChange,
	customValueHandler,
}) => {
	const { language } = useAppSelector((state: RootState) => state.lang);
	const dispatch = useDispatch();
	const classes = useStyles();
	const { customTitle, customPlaceholder } = useTitleAndPlaceholder(criteriaFull);

	/**
	 * Метод для записи в стейт выбранных опций из селекта
	 */
	const handleValue = (
		values: OptionType[],
		itemLogic?: Logic | undefined,
		itemId?: number | undefined,
	) => {
		// функция для отчетов
		if (isHandlerFunction && handlerOnChange) {
			handlerOnChange(values, criteriaCurrent, itemLogic);
		}
		dispatch(searchSlice.actions.setCurrentTemplate(null));

		const eventConverterResult = eventConverter(values);
		if (criteriaFull) {
			// TODO: вызов setActiveCriteriaValues в отчетах приводит к ошибке (см. DEV-698)
			// он нужен для станицы звонков и дополнительных параметров в отчетах
			// отключили для остальных параметров в отчетах (DEV-725)
			if (page !== 'reports_block' && page !== 'markup') {
				const data = getDataForActiveCriteria(
					criteriaFull,
					[...eventConverterResult],
					itemLogic,
					itemId,
				);
				dispatch(searchSlice.actions.setActiveCriteriaValues({
					page: block || page,
					data,
				}));
			}
		}
	};

	const onCustomValueHandler = (
		values: OptionType[],
		itemLogic?: Logic | undefined,
		itemId?: number | undefined,
	) => {
		if (customValueHandler) customValueHandler(values, itemLogic, itemId);
	};

	const criteriaFullKey = criteriaFull.key === 'client_phrases' || criteriaFull.key === 'operator_phrases';
	const backgroundColor = criteriaFullKey ? variables.orange_2 : variables.purple_2;
	const color = criteriaFullKey ? variables.orange_8 : variables.purple_7;
	const border = criteriaFullKey ? variables.gold_4 : variables.purple_4;

	return (
		<div className={cn(classes.searchItem, className)}>
			<MultiValueSelect
				criteriaKey={criteriaCurrent.key}
				conditionItemId={conditionItemId}
				hasConditions={hasConditions || false}
				logic={criteriaCurrent.logic || logic}
				selectAll={!(criteriaCurrent.key in multiSelectsWithoutDropdown)}
				value={converter(criteriaCurrent)}
				options={converter(criteriaFull)}
				selectType={criteriaFull.selectType}
				title={customTitle || title || criteriaFull.title}
				placeholder={customPlaceholder || translate('all', language)}
				backgroundColor={backgroundColor}
				color={color}
				border={border}
				valueHandler={customValueHandler ? onCustomValueHandler : handleValue}
			/>
		</div>
	);
});

export default CriteriaItem;
