/* eslint-disable */
import React, { FC } from 'react';
import clsx from 'clsx';
import './DropdownWithChildren.css';

interface DropdownWithChildrenProps {
	dropdown: boolean;
	dropdownCloseFunction: () => void;
	position?: 'left' | 'right';
	customWidth?: boolean;
	padding?: string;
}

// при необходимости можно добавить разное расположение

const DropdownWithChildren: FC<DropdownWithChildrenProps> = ({
	dropdown,
	dropdownCloseFunction,
	children,
	position,
	customWidth,
	padding,
}) => (
	<div
		className={clsx('', {
			shown: dropdown,
			dropdown: !dropdown,
			positionLeft: position === 'left',
			positionRight: position === 'right',
			customWidth,
		})}
		style={{
			padding,
		}}
	>
		{children}
	</div>
);

export default DropdownWithChildren;
