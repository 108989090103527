/* eslint-disable */
import React, { FC } from 'react';

import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

import { colors } from './ChartsBlock';

type ChartBarPropsType = {
	data: {}[];
	width: number;
	height: number;
	dataKey: string[];
	checkChart: string;
	dataChartLegend: string[];
	dataLegendLabelX: string;
	dataLegendLabelY: string;
};

const useStyles = makeStyles({
	chartListItemWrap: {
		marginTop: '16px',
		display: '-webkit-box',
		'-webkit-line-clamp': 6,
		'-webkit-box-orient': 'vertical',
		overflowY: 'hidden',
		textAlign: 'center',
	},
	chartList: {
		marginBottom: '0',
		textAlign: 'center',
	},
	chartListItem: {
		display: 'inline-flex',
		alignItems: 'center',
		marginRight: '10px',
		fontSize: '11px',
	},
	chartTooltip: {
		maxHeight: '400px',
		height: '100%',
		background: '#fff',
		padding: '16px',
		borderRadius: '10px',
		boxShadow: '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
		width: '300px',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			height: '8px',
			width: '9px',
			backgroundColor: '#fff',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundСolor: '#A3AEBE',
			background: '#A3AEBE',
			borderRadius: '14px',
		},
	},
});

const ChartBar: FC<ChartBarPropsType> = React.memo(
	({ data, width, height, dataKey, checkChart, dataChartLegend, dataLegendLabelX, dataLegendLabelY }) => {
		const classes = useStyles();
		const renderLegend = (props: any) => {
			const { payload } = props;
			return (
				<div className={classes.chartListItemWrap}>
					<ul className={classes.chartList}>
						{payload.map((entry: any, index: any) => (
							<div key={index} className={classes.chartListItem}>
								<span
									style={{
										fontSize: '12px',
										background: `${entry.color}`,
										borderRadius: '50%',
										width: '6px',
										height: '6px',
										marginRight: '5px',
									}}
								/>
								<li style={{ color: `${entry.color}`, display: 'inline-flex' }} key={`item-${index}`}>
									{dataChartLegend[index]}
								</li>
							</div>
						))}
					</ul>
				</div>
			);
		};

		const CustomizedTooltip = ({ active, payload, label }: any) => {
			if (active && payload && payload.length) {
				return (
					<div className={classes.chartTooltip}>
						<div style={{ marginBottom: '8px' }}>
							<span>{dataLegendLabelX} -&nbsp;</span>
							<span>{label}</span>
						</div>
						<div style={{ height: '100%' }}>
							<table>
								<tbody>
									{payload.map((item: any, index: any) => (
										<tr key={index} style={{ color: `${item.fill}` }}>
											<td>{dataChartLegend[index]}:</td>
											<td style={{ paddingLeft: '10px', whiteSpace: 'nowrap' }}>
												{checkChart === 'seconds' || checkChart === 'percent'
													? checkChart === 'seconds'
														? moment.utc(item.value * 1000).format('HH:mm:ss')
														: `${item.value} %`
													: item.value}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				);
			}
			return null;
		};
		return (
			<ResponsiveContainer width="100%" height={height}>
				<BarChart
					width={width}
					height={height}
					data={data}
					margin={{
						top: 5,
						right: 20,
						left: 20,
						bottom: 5,
					}}
					style={{ fontSize: '12px' }}
				>
					<XAxis
						dataKey="idName"
						label={{
							value: `${dataLegendLabelX}`,
							offset: -5,
							position: 'insideBottomRight',
							fontSize: '13px',
						}}
					/>

					{checkChart === 'seconds' ? (
						<YAxis
							label={{
								value: `${dataLegendLabelY}`,
								angle: -90,
								position: 'insideLeft',
								viewBox: { x: 10, y: 30, width: '100%', height: 30 },
								fontSize: '13px',
							}}
							tickFormatter={(timeStr) => moment.utc(timeStr * 1000).format('HH:mm:ss')}
						/>
					) : (
						<YAxis
							label={{
								value: `${dataLegendLabelY}`,
								angle: -90,
								position: 'insideLeft',
								viewBox: { x: 10, y: 30, width: '100%', height: 30 },
								fontSize: '13px',
							}}
						/>
					)}

					<ReferenceLine y={0} stroke="#000" />
					<Tooltip wrapperStyle={{ zIndex: 100, pointerEvents: 'auto' }} content={<CustomizedTooltip />} />
					<Legend style={{ bottom: '0px' }} content={renderLegend} iconType="circle" />
					{dataKey.map((item, index) => (
						<Bar key={index} dataKey={item} fill={colors[index % colors.length]} stackId="stack" />
					))}
				</BarChart>
			</ResponsiveContainer>
		);
	},
);

export default ChartBar;
