/* eslint-disable */
import React, { FC } from 'react';

import { PieChart, Pie, Tooltip, Cell, LabelList, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import moment from 'moment';
import { useAppSelector } from '../../../hooks/redux';

import { RootState } from '../../../store/store';
import { translate } from '../../../localizations';

import { colors } from './ChartsBlock';

type ChartPiePropsType = {
	data: {}[];
	width: number;
	height: number;
	dataKey: string;
	checkChart: string;
};

const useStyles = makeStyles({
	chartList: {
		display: 'flex',
		flexWrap: 'wrap',
		marginBottom: '0',
		justifyContent: 'center',
	},
	chartListItem: {
		display: 'flex',
		alignItems: 'center',
		marginRight: '10px',
	},
	chartTooltip: {
		background: '#fff',
		padding: '16px',
		borderRadius: '10px',
		boxShadow: '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
		width: '300px',
	},
	chartTitle: {
		position: 'absolute',
		top: '0px',
		left: '50%',
		transform: 'translate(-50%, 0)',
		fontWeight: '700 !important',
		textAlign: 'center',
		marginBottom: '5px !important',
	},
});

const ChartPie: FC<ChartPiePropsType> = React.memo(({ data, width, height, dataKey, checkChart }) => {
	const { language } = useAppSelector((state: RootState) => state.lang);
	const classes = useStyles();

	const CustomizedTooltip = ({ active, payload }: any) => {
		if (active && payload && payload.length) {
			return (
				<div className={classes.chartTooltip}>
					<table>
						<tbody>
							{payload.map((item: any, index: any) => (
								<tr key={index}>
									<td>{payload[0].payload.idName}:</td>
									<td
										style={{
											paddingLeft: '10px',
											whiteSpace: 'nowrap',
											color: `${payload[0].payload.fill}`,
										}}
									>
										{checkChart === 'seconds' || checkChart === 'percent'
											? checkChart === 'seconds'
												? moment.utc(item.value * 1000).format('HH:mm:ss')
												: `${item.value} %`
											: item.value}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			);
		}
		return null;
	};

	const CustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }: any) => {
		const RADIAN = Math.PI / 180;
		// eslint-disable-next-line
		const radius = 25 + innerRadius + (outerRadius - innerRadius);
		// eslint-disable-next-line
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		// eslint-disable-next-line
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				style={{ fontSize: '11px' }}
				x={x}
				y={y}
				fill={colors[index % colors.length]}
				textAnchor={x > cx ? 'start' : 'end'}
				dominantBaseline="central"
			>
				{checkChart === 'seconds' ? moment.utc(value * 1000).format('HH:mm:ss') : value}
			</text>
		);
	};

	return (
		<div style={{ position: 'relative' }}>
			<Typography className={classes.chartTitle}>{translate('reportAll', language)}</Typography>
			<ResponsiveContainer width="100%" height={height}>
				<PieChart
					width={400}
					height={height}
					margin={{
						top: 15,
						right: 20,
						left: 20,
						bottom: 5,
					}}
				>
					<Pie
						data={data}
						dataKey={dataKey}
						cx="50%"
						cy="50%"
						outerRadius={110}
						fill="#B37FEB"
						label={data.length < 50 ? CustomizedLabel : undefined}
					>
						{data.map((entry: any, index) => (
							<Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
						))}
					</Pie>
					<Tooltip wrapperStyle={{ zIndex: 100 }} content={<CustomizedTooltip />} />
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
});

export default ChartPie;
