import React, { FC, ReactElement } from 'react';
import { Typography } from '@mui/material';
import { Tooltip } from 'components/common';
import { InfoCircleActive } from 'components/Icons/InfoCircleActiveIcon';

interface IMultiSelectWrapper {
	selectComp: ReactElement;
	rootClassName: string;
	tileClassName: string;
	wrapperClassName: string;
	title?: string;
	help?: string;
}
const MultiSelectWrapper: FC<IMultiSelectWrapper> = ({
	selectComp,
	rootClassName,
	tileClassName,
	wrapperClassName,
	title,
	help,
}) => (
	<div className={rootClassName}>
		{title !== 'no_title' && (
			<Typography className={tileClassName}>
				{title}
			</Typography>
		)}
		<div className={wrapperClassName}>
			{selectComp}
			{help && (
				<div style={{ marginLeft: '5px' }}>
					<Tooltip title={help ?? ''}>
						<InfoCircleActive />
					</Tooltip>
				</div>
			)}
		</div>
	</div>
);

export default MultiSelectWrapper;
