/* eslint-disable */
import React, { FC } from 'react';
import { styled } from '@mui/styles';
import { Checkbox } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import { TitleWithTooltip } from 'shared/ui';

interface CustomCheckboxProps extends CheckboxProps{
	style?: Record<string, any>;
	bg?: string;
	id?: string;
	label?: string;
	tooltip?: string;
	rootClassname?: string;
}

const CustomCheckbox: FC<CustomCheckboxProps> = ({
	style,
	bg,
	id,
	label,
	tooltip,
	rootClassname,
	...props
}) => {
	const BpIcon = styled('span')(({ theme }: any) => ({
		borderRadius: 2,
		border: '1px solid #A3AEBE',
		width: 16,
		height: 16,
		backgroundColor: '#E3E8EF',
	}));

	const BpCheckedIcon = styled(BpIcon)({
		backgroundColor: bg || '#9254DE',
		borderColor: bg || '#9254DE',
		'&:before': {
			display: 'block',
			width: 15,
			height: 14,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
	});

	return (
		<label
			className={rootClassname}
			htmlFor={id}
			style={{alignItems: "center", display: "flex", color: 'rgb(115, 128, 148)'}}
		>
			<Checkbox
				id={id}
				sx={{
					'&:hover': { bgcolor: 'transparent' },
					padding: '0',
				}}
				disableRipple
				color="default"
				checkedIcon={<BpCheckedIcon />}
				icon={<BpIcon />}
				inputProps={{ 'aria-label': 'Checkbox demo' }}
				style={style}
				{...props}
			/>
			{label && (
				<>
					{tooltip ? (
						<TitleWithTooltip
							title={label}
							tooltipTitle={tooltip}
							theme="gray"
							size="md"
						/>
					) : <span>{label}</span>}
				</>
			)}
		</label>
	);
};

export default CustomCheckbox;
