import React, { FC, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Typography } from '@mui/material';

import styles from './styles.module.scss';

interface VerticalMenuItemProps {
	text: string;
	to: string;
	parentRoute?: string;
	inDev?: boolean;
	className?: string;
	indicator?: ReactElement | null;
}

export const VerticalMenuItem: FC<VerticalMenuItemProps> = ({
	text,
	to,
	inDev,
	className,
	parentRoute,
	indicator,
}) => {
	const isActive = parentRoute
		? window.location.pathname.includes(parentRoute) : window.location.pathname.includes(to);

	return (
		<NavLink
			to={to}
			className={cn(styles.menuItem, className, {
				[styles.active]: isActive,
			})}
		>
			<div className={styles.content}>
				<Typography
					className={cn(styles.menuItemText, {
						[styles.activeMenuItemText]: isActive
					})}
				>
					{text}
				</Typography>
				{indicator}
			</div>
			{inDev && (
				<div className={styles.devIndicator}>
					<Typography>dev</Typography>
				</div>
			)}
		</NavLink>
	);
};
